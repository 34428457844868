// @flow

import superagent from 'superagent';
import type { Saga } from 'redux-saga';
import jwtDecode from 'jwt-decode';
import { push } from 'react-router-redux';
import { debounce, has } from 'lodash';
import moment from 'moment';
import canAutoplay from 'can-autoplay';
import ZoomRoomClient from 'blend2-client/dist/zoom-room';
import { getDetectBlendChannel, blendDevice, getIsDeviceAvailable, getIsBluescapeAvailable, getIsZoomRoomAvailable, getMacAddress, getIpAddress, apiClient } from 'blend2-client';
import { braidClient, getReduxChannel, cachedSnapshot, cachedValue } from '@bunchtogether/boost-client';
import '@bunchtogether/boost-client/dist/browser-cache';
import boltClient from '@bunchtogether/bolt-client';
import '@bunchtogether/bolt-client/localstorage';
import { List, is, Map as ImmutableMap } from 'immutable';
import { eventChannel, END, buffers } from 'redux-saga';
import { call, select, put, take, takeLatest, fork, spawn, delay, actionChannel } from 'redux-saga/effects';
import { TYPE_BAND_CHANNEL } from 'shared-redux/src/graph/constants';
import { updateBandDevice, changeScreenChannel } from 'band-redux/src/app/actions';
import { updateZoomMeeting, joinMeeting, endMeeting } from 'band-redux/src/zoom/actions';
import { CLEAR_SCREEN_CHANNEL } from 'band-redux/src/app/constants';
import { agent, setToken } from '../../lib/api-agent'; // Import agent for API access
import { sign, generateId, generatePrivateKey, generatePublicKey, parsePrivateKey } from '../../lib/cluster';
import { isIE } from '../../lib/useragent';
import {
  deviceIdSelector,
  teamNameSelector,
  metadataIdSelector,
  activeChannelIdSelector,
  teamIdSelector,
  availableSourcesSelector,
  audioSourceSelector,
  zoomRoomPasscodeSelector,
  zoomRoomAvailableSelector,
  zoomMeetingIdSelector,
  zoomMeetingNodeIdSelector,
  meetingVolumeSelector,
  hardwareManagerIpSelector,
  blendExtensionStatusSelector,
  clock24HrSelector,
  zoomClientMeetingIdSelector,
} from './selectors';
import {
  setDeviceMetadata,
  setWindowSize,
  setCanAutoplayUnmuted,
  setZoomRoomPasscode,
  setZoomRoomAvailable,
  setBluescapeAvailable,
  addNotification,
  updateDevice,
  closeDialog,
  openDialog,
  updateMeeting,
  setRemoteUrl,
} from './actions';
import { getUserAgentParameters } from '../../lib/device';
import * as constants from './constants';
import canUseWebRtcPromise from '../../lib/can-use-webrtc';
import safeReload from '../../lib/safe-reload';
import erd from '../../lib/element-resize-detector';
import makeLogger from '../../lib/logger';

const logger = makeLogger('App Sagas');

const BRAID_PROTOCOL = process.env.PROJECT_PROTOCOL ? (process.env.PROJECT_PROTOCOL === 'https' ? 'wss' : 'ws') : (window.location.protocol === 'http:' ? 'ws' : 'wss'); // eslint-disable-line no-nested-ternary
const PROJECT_PROTOCOL = process.env.PROJECT_PROTOCOL || window.location.protocol.replace(':', '');
const PROJECT_HOST = process.env.PROJECT_HOST || window.location.hostname;
const PROJECT_PORT = process.env.PROJECT_PORT || window.location.port || (PROJECT_PROTOCOL === 'https' ? 443 : 80);

const BAND_URL = `${PROJECT_PROTOCOL}://${PROJECT_HOST}:${PROJECT_PORT}`;
const BRIDGE_URL = process.env.BRIDGE_URL || BAND_URL;

const onloadPromise = new Promise((resolve) => {
  window.addEventListener('load', resolve);
});

if (window && document && document.documentElement && document.documentElement.style) {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

if (window && window.location && window.location.search && window.location.search.indexOf('persistKey') !== -1) {
  window.localStorage.setItem('persistKey', 'true');
}

window.braid = braidClient;

braidClient.on('error', (error) => {
  const { name, code } = error;
  if (typeof name === 'string') {
    if (typeof code === 'string' || typeof code === 'number') {
      logger.error(`Encountered ${name} with code ${code}`);
    } else {
      logger.error(`Encountered ${name}`);
    }
  } else {
    logger.error('Encountered unknown error');
  }
  logger.errorObject(error);
});

export function* updateSizeSaga(): Saga<*> {
  const body = document.body;
  if (!body) {
    return;
  }
  const channel = eventChannel((emit: Function) => {
    const handler = () => {
      emit(true);
    };
    const debouncedHandler = debounce(handler, 100);
    erd.listenTo(body, debouncedHandler);
    return () => {
      erd.removeListener(body, debouncedHandler);
    };
  }, buffers.expanding(2));
  try {
    while (true) {
      yield take(channel);
      if (window && document && document.documentElement && document.documentElement.style) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        yield put(setWindowSize(window.innerWidth, window.innerHeight));
      }
    }
  } finally {
    logger.debug('Update size event channel terminated.');
  }
}

export function* updateClockSaga(): Saga<*> {
  const is24Hr = yield select(clock24HrSelector);
  const timeFormat = is24Hr ? 'HH:mm' : 'LT';
  const channel = eventChannel((emit: Function) => {
    let interval;
    const setClock = () => {
      const o = new Date();
      const time = moment(o).format(timeFormat);
      const date = moment(o).format('LL');
      emit({ type: constants.SET_CLOCK, value: [time, date] });
    };
    setTimeout(() => {
      interval = setInterval(setClock, 2 * 1000);
      setClock();
    }, 2 * 1000 - Date.now() % (2 * 1000));
    return () => {
      clearInterval(interval);
    };
  }, buffers.expanding(2));
  try {
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } finally {
    logger.debug('Update clock event channel terminated.');
  }
}

function* getTeamClientSaga(teamName: string): Saga<*> {
  let teamClient;
  const teamClientString = window.localStorage.getItem('teamClient');
  if (teamClientString) {
    teamClient = JSON.parse(teamClientString);
    return teamClient;
  }
  try {
    const response = yield agent
      .get('/api/v1.0/client')
      .query({ link: teamName, applicationType: 'web' });
    teamClient = response.body;
    window.localStorage.setItem('teamClient', JSON.stringify(teamClient));
    return teamClient;
  } catch (error) {
    if (error.response && error.response.body && error.response.body.error) {
      yield put({ type: constants.LOGIN_ERROR, value: error.response.body.error });
    }
    throw error;
  }
}

function* getDeviceIdSaga(): Saga<*> { // eslint-disable-line
  let deviceId = yield select(deviceIdSelector);
  if (deviceId) {
    return deviceId;
  }
  deviceId = window.localStorage.getItem('deviceId');
  if (deviceId) {
    return deviceId;
  }
  throw new Error('Could not get deviceId');
}

function* checkKerberosLoginSaga(applicationUuid: string): Saga<*> {
  try {
    const response = yield agent
      .get(`/kerberos/${applicationUuid}`)
      .withCredentials();
    return response.body;
  } catch (error) {
    clearUserAuthDataCache();
    return null;
  }
}

let useKerberos = true;

export function* kerberosUserLoginSaga(): Saga<*> {
  try {
    const teamId = yield* getApplicationTeamIdSaga();
    const teamName = yield* getTeamNameSaga();
    const teamClient = yield* getTeamClientSaga(teamName);
    const kerberosInfo = yield* checkKerberosLoginSaga(teamClient.application_id);
    if (!kerberosInfo) {
      logger.warn('Kerberos authentication is not available for this device.');
      useKerberos = false;
      return null;
    }
    const params = {
      client_id: teamClient.client_id,
      grant_type: 'kerberos',
      scope: 'openid bridge profile email',
      token: kerberosInfo.token,
      adapter: kerberosInfo.adapterUuid,
      utctime: new Date().toUTCString(),
      provider: kerberosInfo.providerUuid,
    };
    const response = yield call(() => agent.post('/token')
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .send(params));

    const authData = response.body;
    authData.expires_at = Math.round(Date.now() / 1000 + parseInt(authData.expires_in, 10));
    const accessTokenExpiration = `${Date.now() + authData.expires_in * 1000}`;
    const jwt = jwtDecode(authData.id_token);

    window.localStorage.setItem('clientUserId', jwt.sub);
    window.localStorage.setItem('clientAuthData', JSON.stringify(authData));
    window.localStorage.setItem('clientAuthDataExpiration', accessTokenExpiration);

    yield call(() => agent.post('/api/v1.0/auth/band').send({ authData, teamId }));
    yield put({ type: constants.SET_USER_ID, value: jwt.sub });
    return authData.access_token;
  } catch (error) {
    logger.error('Failed to complete kerberos login');
    logger.errorObject(error);
    return null;
  }
}

export function* userLoginSaga(): Saga<*> {
  try {
    clearApplicationCache();
    const teamName = yield* getTeamNameSaga();
    const teamClient = yield* getTeamClientSaga(teamName);
    window.location = encodeURI(`${BRIDGE_URL}/auth?${[
      `response_type=${teamClient.response_type}`,
      `client_id=${teamClient.client_id}`,
      `redirect_uri=${PROJECT_PROTOCOL}://${PROJECT_HOST}`,
      `scope=${teamClient.scope}`,
      `nonce=${teamClient.nonce}`,
      'prompt=consent',
    ].join('&')}`);
  } catch (error) {
    logger.error('Error in user login');
    logger.errorObject(error);
  }
}

function* logoutUserSaga(): Saga<*> {
  try {
    clearUserAuthDataCache();
    yield put({ type: constants.SET_USER_ID, value: null });
    const deviceAccessToken = yield* getDeviceAccessTokenSaga();
    yield put({ type: constants.SET_DEVICE_ACCESS_TOKEN, value: deviceAccessToken });
    const braidUrl = `${BRAID_PROTOCOL}://${PROJECT_HOST}:${PROJECT_PORT}/braid`;
    const braidOptions: Object = { accessToken: deviceAccessToken };
    if (braidClient.ws) {
      yield call(braidClient.sendCredentials.bind(braidClient), braidOptions);
    } else {
      yield call(braidClient.open.bind(braidClient), braidUrl, braidOptions);
    }
  } catch (error) {
    logger.error('Error in user logout');
    logger.errorObject(error);
  }
}

function* getApplicationTeamIdSaga() {
  const application = yield* getApplicationSaga();
  return application.metadata.teamId;
}

function* getTeamIdSaga() {
  const teamName = yield* getTeamNameSaga();
  let response;
  try {
    response = yield call(() => agent.get(`/api/v1.0/link/${teamName}-public`));
  } catch (error) {
    if (error.response && error.response.body && error.response.body.error) {
      logger.error(error.response.body.error);
    }
    yield put({ type: constants.LOGIN_ERROR, value: 'Unable to find team.' });
    yield* clearTeamNameCache();
    throw new Error('Unable to get team ID');
  }
  if (response && response.body && response.body.uuid) {
    const teamId = response.body.uuid;
    return teamId;
  }
  throw new Error('Could not get team ID');
}

function* clearTeamNameCache() {
  logger.debug('Clearing team name cache');
  window.localStorage.removeItem('clientTeamName');
  logger.debug('Clearing team client cache');
  window.localStorage.removeItem('teamClient');
  yield put({ type: constants.SET_TEAM_NAME, value: undefined });
}

function* getTeamNameSaga(): Saga<*> {
  let teamName = yield select(teamNameSelector);
  if (teamName) {
    window.localStorage.setItem('clientTeamName', teamName);
    return teamName;
  }
  teamName = window.localStorage.getItem('clientTeamName');
  if (teamName) {
    return teamName;
  }
  let response;
  try {
    response = yield call(() => agent.get(`${BAND_URL}/api/v1.0/team/default`));
  } catch (error) {
    logger.error('Error fetching default team');
    logger.errorObject(error);
    throw error;
  }
  if (response && response.body && response.body.defaultTeam) {
    teamName = response.body.defaultTeam;
  }
  if (teamName) {
    window.localStorage.setItem('clientTeamName', teamName);
    return teamName;
  }
  throw new Error('Could not get team name');
}

const clearApplicationCache = () => window.localStorage.removeItem('clientApplication');


function* getApplicationSaga() {
  let application;
  const applicationString = window.localStorage.getItem('clientApplication');
  if (applicationString) {
    application = JSON.parse(applicationString);
    return application;
  }
  try {
    const teamId = yield* getTeamIdSaga();
    const response = yield call(() => agent.get(`/api/v1.0/metadata/${teamId}`));
    application = { id: teamId, type: 'metadata', metadata: response.body };
    window.localStorage.setItem('clientApplication', JSON.stringify(application));
    return application;
  } catch (error) {
    if (error.response && error.response.body && error.response.body.error) {
      logger.error(error.response.body.error);
    } else {
      logger.errorStack(error);
    }
    throw new Error('Unable to get application');
  }
}


const parseHash = (hash: string): Object => (
  hash.slice(1).split('&').reduce((hashObj: Object, hashStr: string) => {
    const split = hashStr.split('=');
    hashObj[split[0]] = split[1]; // eslint-disable-line no-param-reassign
    return hashObj;
  }, {})
);

const clearUserAuthDataCache = () => {
  const userId = window.localStorage.getItem('clientUserId');
  const authDataString = window.localStorage.getItem('clientAuthData');
  const accessTokenExpiration = window.localStorage.getItem('clientAuthDataExpiration');
  if (!userId && !authDataString && !accessTokenExpiration) {
    return;
  }
  logger.debug('Clearing user authData');
  window.localStorage.removeItem('clientUserId');
  window.localStorage.removeItem('clientAuthData');
  window.localStorage.removeItem('clientAuthDataExpiration');
};

function* handleUserAccessTokenHashSaga() {
  const teamId = yield* getApplicationTeamIdSaga();
  const authData = parseHash(window.location.hash);
  authData.expires_at = Math.round(Date.now() / 1000 + parseInt(authData.expires_in, 10));
  const jwt = jwtDecode(authData.id_token);
  yield put({ type: constants.SET_USER_ID, value: jwt.sub });
  window.localStorage.setItem('clientUserId', jwt.sub);
  window.localStorage.setItem('clientAuthData', JSON.stringify(authData));
  window.localStorage.setItem('clientAuthDataExpiration', `${Date.now() + authData.expires_in * 1000}`);
  yield put(push(window.location.pathname));
  try {
    yield call(() => agent.post('/api/v1.0/auth/band').send({ authData, teamId }));
  } catch (error) {
    logger.error('Error registering user token hash');
    logger.errorObject(error);
    throw error;
  }
  yield put({ type: constants.SET_USER_ID, value: jwt.sub });
  if (authData.code) {
    yield* getUserRefreshTokenSaga(authData.code);
  }
  return authData.access_token;
}

function* getUserRefreshTokenSaga(code: string) {
  const teamId = yield* getApplicationTeamIdSaga();
  const teamName = yield* getTeamNameSaga();
  const teamClient = yield* getTeamClientSaga(teamName);
  const clientId = teamClient.client_id;
  try {
    const response = yield call(() => agent.post('/token')
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .send({
        code,
        grant_type: 'authorization_code',
        client_id: clientId,
        redirect_uri: `${PROJECT_PROTOCOL}://${PROJECT_HOST}`,
      }));
    const authData = response.body;
    authData.expires_at = Math.round(Date.now() / 1000 + parseInt(authData.expires_in, 10));
    yield call(() => agent.post('/api/v1.0/auth/band').send({ authData, teamId }));
    const accessTokenExpiration = `${Date.now() + authData.expires_in * 1000}`;
    window.localStorage.setItem('clientAuthData', JSON.stringify(authData));
    window.localStorage.setItem('clientAuthDataExpiration', accessTokenExpiration);
  } catch (error) {
    logger.error('Error getting user refresh token');
    logger.errorObject(error);
    throw error;
  }
}

function* getUserAccessTokenSaga(attempt?: number = 1) {
  if (window.location.hash) {
    const parsedHashData = parseHash(window.location.hash);
    if (has(parsedHashData, 'id_token') && has(parsedHashData, 'access_token')) {
      const accessToken = yield* handleUserAccessTokenHashSaga();
      return accessToken;
    }
  }
  const userId = window.localStorage.getItem('clientUserId');
  const authDataString = window.localStorage.getItem('clientAuthData');
  let accessTokenExpiration = window.localStorage.getItem('clientAuthDataExpiration');
  if (!userId && !authDataString && !accessTokenExpiration) {
    return null;
  }
  if (!userId || !authDataString || !accessTokenExpiration) {
    clearUserAuthDataCache();
    return null;
  }
  let authData = JSON.parse(authDataString);
  if (accessTokenExpiration && (parseInt(accessTokenExpiration, 10) - FIVE_MINUTES) > Date.now()) {
    yield put({ type: constants.SET_USER_ID, value: userId });
    return authData.access_token;
  }

  // Refresh Token login flow
  const { refresh_token } = authData; // eslint-disable-line camelcase
  if (!refresh_token) { // eslint-disable-line camelcase
    return null;
  }
  const teamName = yield* getTeamNameSaga();
  const teamClient = yield* getTeamClientSaga(teamName);
  const clientId = teamClient.client_id;
  if (!clientId) {
    return null;
  }
  const teamId = yield* getApplicationTeamIdSaga();
  let response;
  try {
    response = yield call(() => agent.post('/token')
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .send({
        refresh_token, // eslint-disable-line camelcase
        grant_type: 'refresh_token',
        client_id: clientId,
      }));
  } catch (error) {
    clearUserAuthDataCache();
    if (attempt > 3) {
      throw error;
    }
    logger.error(`Retry attempt ${attempt} following user access token error: ${error.message}`);
    yield delay(attempt * attempt * 5 * 1000);
    const result = yield* getUserAccessTokenSaga(attempt + 1);
    return result;
  }
  authData = response.body;
  authData.expires_at = Math.round(Date.now() / 1000 + parseInt(authData.expires_in, 10));
  accessTokenExpiration = `${Date.now() + authData.expires_in * 1000}`;
  window.localStorage.setItem('clientAuthData', JSON.stringify(authData));
  window.localStorage.setItem('clientAuthDataExpiration', accessTokenExpiration);
  try {
    yield call(() => agent.post('/api/v1.0/auth/band').send({ authData, teamId }));
  } catch (error) {
    clearUserAuthDataCache();
    if (attempt > 3) {
      throw error;
    }
    logger.error(`Retry attempt ${attempt} following user access token error: ${error.message}`);
    yield delay(attempt * attempt * 5 * 1000);
    const result = yield* getUserAccessTokenSaga(attempt + 1);
    return result;
  }
  yield put({ type: constants.SET_USER_ID, value: userId });
  return authData.access_token;
}

const clearDeviceAccessTokenCache = () => {
  window.localStorage.removeItem('deviceAccessToken');
  window.localStorage.removeItem('deviceAccessTokenExpiration');
  window.localStorage.removeItem('deviceRegistered');
  window.localStorage.removeItem('deviceId');
  window.localStorage.removeItem('deviceName');
  window.localStorage.removeItem('deviceNickname');
};

const FIVE_MINUTES = 5 * 60 * 1000;

function* getDeviceAccessTokenSaga(attempt?: number = 1) {
  const application = yield* getApplicationSaga();
  yield put({ type: constants.SET_APPLICATION, value: application });
  let accessToken = window.localStorage.getItem('deviceAccessToken');
  let deviceId = window.localStorage.getItem('deviceId');
  let accessTokenExpiration = window.localStorage.getItem('deviceAccessTokenExpiration');
  if (deviceId) {
    yield put({ type: constants.DEVICE_LOGIN, value: deviceId });
  }
  if (deviceId && accessToken && accessTokenExpiration) {
    if (parseInt(accessTokenExpiration, 10) - FIVE_MINUTES > Date.now()) {
      const teamName = yield* getTeamNameSaga();
      yield put({ type: constants.SET_TEAM_NAME, value: teamName });
      setToken(accessToken);
      return accessToken;
    }
  }
  const privateKey = yield* getPrivateKeySaga();
  const clientId = application.metadata.client.client_id;
  const providerId = application.metadata.provider.uuid;
  const teamId = application.metadata.teamId;
  if (!deviceId) {
    deviceId = generateId(privateKey);
    const publicKey = generatePublicKey(privateKey);
    const data = { id: deviceId, publicKey, type: 'band' };
    try {
      yield call(() => agent.put(`/provider/publickey/${providerId}/user/${deviceId}`)
        .send(data)
        .set('x-signature', sign(privateKey, data)));
    } catch (error) {
      clearApplicationCache();
      yield* clearTeamNameCache();
      clearDeviceAccessTokenCache();
      if (attempt > 3) {
        throw error;
      }
      logger.error(`Retry attempt ${attempt} following error: ${error.message}`);
      yield delay(attempt * attempt * 5 * 1000);
      const result = yield* getDeviceAccessTokenSaga(attempt + 1);
      return result;
    }
    window.localStorage.setItem('deviceId', deviceId);
  }
  const utctime = new Date().toUTCString();
  const params = {
    client_id: clientId,
    grant_type: 'crypto',
    scope: 'openid bridge',
    id: deviceId,
    signature: sign(privateKey, `${deviceId}:${utctime}`),
    utctime,
    provider: providerId,
  };
  let response;
  try {
    response = yield call(() => agent.post('/token')
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .send(params));
  } catch (error) {
    clearApplicationCache();
    yield* clearTeamNameCache();
    clearDeviceAccessTokenCache();
    if (attempt > 3) {
      throw error;
    }
    logger.error(`Retry attempt ${attempt} following device access token error: ${error.message}`);
    yield delay(attempt * attempt * 5 * 1000);
    const result = yield* getDeviceAccessTokenSaga(attempt + 1);
    return result;
  }
  const authData: Object = response.body;
  authData.expires_at = Math.round(Date.now() / 1000 + parseInt(authData.expires_in, 10));
  const scalaPlayerId = window.localStorage.getItem('scalaPlayerId');
  const scalaAccountId = window.localStorage.getItem('scalaAccountId');
  const macAddress = yield call(getMacAddress);
  let isNewDevice;
  try {
    const authResponse = yield call(() => agent.post('/api/v1.0/auth/band/device').send({ authData, teamId, scalaPlayerId, scalaAccountId, macAddress }));
    isNewDevice = authResponse.body.isNewDevice;
  } catch (error) {
    clearApplicationCache();
    yield* clearTeamNameCache();
    clearDeviceAccessTokenCache();
    if (attempt > 3) {
      throw error;
    }
    logger.error(`Retry attempt ${attempt} following error: ${error.message}`);
    yield delay(attempt * attempt * 5 * 1000);
    const result = yield* getDeviceAccessTokenSaga(attempt + 1);
    return result;
  }
  accessToken = authData.access_token;
  accessTokenExpiration = `${Date.now() + authData.expires_in * 1000}`;
  window.localStorage.setItem('deviceAccessToken', accessToken);
  window.localStorage.setItem('deviceAccessTokenExpiration', accessTokenExpiration);
  yield put({ type: constants.DEVICE_LOGIN, value: deviceId });
  setToken(accessToken);
  if (isNewDevice) {
    const canUseWebRtc = yield call(() => canUseWebRtcPromise);
    let userAgent: Object = getUserAgentParameters();
    if (!userAgent) {
      yield delay(1000);
      userAgent = getUserAgentParameters();
    }
    userAgent.deviceType = scalaPlayerId ? 'scala' : userAgent.deviceType;
    const deviceOs = userAgent.isKiosk ? 'KIOSK' : userAgent.osName;
    const name = `${macAddress || Date.now().toString(36)}-${deviceOs}-${userAgent.browserName}`.replace(/\s/g, '').toUpperCase();
    const metadata:Object = {
      name,
      canUseWebRtc,
      ...userAgent,
      muted: true,
      volume: 0.75,
      captions: false,
      macAddress,
      scalaAccountId,
    };
    if (macAddress) {
      metadata.privateKey = privateKey.exportKey('pkcs1-private-pem');
      window.localStorage.setItem('didSendPrivateKey', '1');
    }
    yield put(updateBandDevice(deviceId, metadata));
  } else if (macAddress && !window.localStorage.getItem('didSendPrivateKey')) {
    yield put(updateBandDevice(deviceId, {
      privateKey: privateKey.exportKey('pkcs1-private-pem'),
    }));
    window.localStorage.setItem('didSendPrivateKey', '1');
  }
  return accessToken;
}

function* getPrivateKeySaga(): Saga<*> {
  const usePersistentKeyStorage = window.localStorage.getItem('persistKey') === 'true';
  let privateKeyString;
  if (usePersistentKeyStorage) {
    const result = yield call(() => superagent.get('http://127.0.0.1:17777'));
    const scalaPlayerId = result.body.id;
    const scalaPlayerName = result.body.name;
    const scalaAccountId = result.body.accountId;
    if (result.body && result.body.value) {
      privateKeyString = JSON.parse(result.body.value);
    }
    window.localStorage.setItem('scalaPlayerId', scalaPlayerId);
    window.localStorage.setItem('scalaAccountId', scalaAccountId);
    yield put({ type: constants.SET_SCALA_DATA, value: { scalaPlayerName, scalaPlayerId, scalaAccountId } });
  } else {
    privateKeyString = window.localStorage.getItem('privateKey');
  }
  if (!privateKeyString) {
    privateKeyString = generatePrivateKey(512);
    if (usePersistentKeyStorage) {
      yield call(() => superagent.post('http://127.0.0.1:17777').send(JSON.stringify(privateKeyString)));
    } else {
      window.localStorage.setItem('privateKey', privateKeyString);
    }
  }
  return parsePrivateKey(privateKeyString);
}

function* deviceLogoutSaga(): Saga<*> {
  yield put({ type: constants.DEVICE_LOGIN, value: null });
  clearApplicationCache();
  yield* clearTeamNameCache();
  clearDeviceAccessTokenCache();
  clearUserAuthDataCache();
}

function* serverReloadEventSaga() {
  const seconds = Math.round(Math.random() * 300);
  const channel = eventChannel((emit: Function) => {
    const handler = () => {
      logger.info(`Scheduling safe reload in ${seconds} seconds after server reload request`);
      setTimeout(safeReload, seconds * 1000);
      emit(END);
    };
    braidClient.addServerEventListener('reload', handler);
    return () => {
      braidClient.removeServerEventListener('reload', handler);
    };
  }, buffers.expanding(2));
  try {
    while (true) {
      yield take(channel);
    }
  } finally {
    logger.debug('Server reload event channel terminated.');
  }
}

function getPathParameters(): Object {
  const pathname = window.location.pathname;
  if (pathname) {
    const path = pathname.slice(1).split('/');
    if (path.length > 1 && !!path[1]) {
      return {
        teamName: path[0],
        channelName: path[1],
      };
    }
    if (path.length === 1 && !!path[0]) {
      return { teamName: path[0] };
    }
  }
  return {};
}

function* teamSetupSaga() {
  const { teamName, channelName } = getPathParameters();
  const cachedTeamName = window.localStorage.getItem('clientTeamName');
  if (teamName && channelName) {
    yield put({ type: constants.SWITCH_CHANNEL, value: { teamName, channelName } });
  } else if (teamName && cachedTeamName !== teamName) {
    yield put({ type: constants.SWITCH_TEAM, value: teamName });
  } else {
    logger.debug('Running setup saga, teamSetupSaga');
    yield* setupSaga();
  }
}

let setupAttempt = 0;
function* setupSaga() {
  braidClient.on('error', (error) => {
    logger.error(`Braid error: ${error.message}`);
  });
  setupAttempt += 1;
  if (setupAttempt > 5) {
    safeReload();
    return;
  }
  try {
    yield* _setupSaga();
  } catch (error) {
    logger.error(`Setup attempt ${setupAttempt} failed, retrying`);
    logger.errorStack(error);
    const retryDelaySeconds = setupAttempt * setupAttempt;
    yield delay(retryDelaySeconds * 1000);
    yield* deviceLogoutSaga();
    yield fork(setupSaga);
  }
}

function* _setupSaga() { // eslint-disable-line no-underscore-dangle
  const deviceAccessToken = yield* getDeviceAccessTokenSaga();
  yield put({ type: constants.SET_DEVICE_ACCESS_TOKEN, value: deviceAccessToken });
  const braidOptions: Object = { accessToken: deviceAccessToken };
  let userAccessToken;
  try {
    userAccessToken = yield* getUserAccessTokenSaga();
  } catch (error) {
    logger.error(`Unable to get user access token: ${error.message}`);
  }
  let kerberosAccessToken;
  if (!userAccessToken) {
    try {
      kerberosAccessToken = yield* kerberosUserLoginSaga();
    } catch (error) {
      logger.error(`Unable to get kerberos access token, ${error.message}`, error);
    }
  }

  yield fork(refreshAccessTokensSaga);

  if (userAccessToken) {
    braidOptions.secondaryAccessToken = userAccessToken;
  }
  if (kerberosAccessToken) {
    braidOptions.secondaryAccessToken = kerberosAccessToken;
  }
  const braidUrl = `${BRAID_PROTOCOL}://${PROJECT_HOST}:${PROJECT_PORT}/braid`;
  if (braidClient.ws) {
    yield call(braidClient.sendCredentials.bind(braidClient), braidOptions);
  } else {
    yield call(braidClient.open.bind(braidClient), braidUrl, braidOptions);
  }

  yield call(waitForOnlineSaga);

  yield fork(deviceMetadataSaga);
  yield fork(initialDeviceMetadataSaga);
  yield fork(teamMetadataSaga);
  yield fork(metadataNodeSaga);
  yield fork(serverReloadEventSaga);
  yield fork(channnelListenerSaga);
  yield fork(layoutVideoSaga);
}

function* zoomDetectionSaga(): Saga<*> {
  let retryAttempts = 0;
  while (true) {
    const isZoomRoomAvailable = yield call(getIsZoomRoomAvailable);
    yield put(setZoomRoomAvailable(isZoomRoomAvailable));
    if (isZoomRoomAvailable || retryAttempts > 10) {
      return;
    }
    retryAttempts += 1;
    const duration = retryAttempts < 8 ? retryAttempts * retryAttempts * 1000 : 60000;
    logger.warn(`%cZoom Rooms: %cNot detected, retrying in ${duration / 1000} seconds`);
    yield delay(duration);
  }
}

function* bluescapeDetectionSaga(): Saga<*> {
  let retryAttempts = 0;
  while (true) {
    const isBluescapeAvailable = yield call(getIsBluescapeAvailable);
    yield put(setBluescapeAvailable(isBluescapeAvailable));
    if (isBluescapeAvailable || retryAttempts > 10) {
      return;
    }
    retryAttempts += 1;
    const duration = retryAttempts < 8 ? retryAttempts * retryAttempts * 1000 : 60000;
    logger.warn(`%cBluescape: %cNot detected, retrying in ${duration / 1000} seconds`);
    yield delay(duration);
  }
}

function* blendExtensionSaga(): Saga<*> {
  const channel = getDetectBlendChannel();
  try {
    while (true) {
      const blendServerDetected = yield take(channel);
      if (blendServerDetected) {
        const macAddress = yield call(getMacAddress);
        const ipAddress = yield call(getIpAddress);
        const deviceId = yield select(deviceIdSelector);
        if (macAddress) {
          yield put(updateBandDevice(deviceId, { macAddress }));
        }
        if (ipAddress) {
          yield put(updateBandDevice(deviceId, { ipAddress }));
        }
        yield fork(bluescapeDetectionSaga);
        yield fork(zoomDetectionSaga);
      }
      yield put({ type: constants.SET_BLEND_EXTENSION, value: blendServerDetected });
      yield* updateHardwareManagerIp();
    }
  } finally {
    logger.debug('Blend detection event channel terminated.');
  }
}

function* refreshAccessTokensSaga() {
  let expiration = Infinity;
  while (expiration > Date.now()) {
    const deviceAccessTokenExpirationString = window.localStorage.getItem('deviceAccessTokenExpiration');
    const clientAuthDataExpirationString = window.localStorage.getItem('clientAuthDataExpiration');
    const deviceAccessTokenExpiration = parseInt(deviceAccessTokenExpirationString, 10);
    const clientAuthDataExpiration = clientAuthDataExpirationString ? parseInt(clientAuthDataExpirationString, 10) : Infinity;
    expiration = Math.min(deviceAccessTokenExpiration, clientAuthDataExpiration) - FIVE_MINUTES;
    const interval = expiration - Date.now();
    if (interval < 0) {
      break;
    }
    logger.debug(`Access token refresh scheduled in ${Math.round(interval / 1000)} seconds.`);
    if ((interval / 1000) > 86400) {
      yield delay(86400 * 1000);
    } else {
      yield delay(interval);
    }
  }
  try {
    const deviceAccessToken = yield* getDeviceAccessTokenSaga();
    yield put({ type: constants.SET_DEVICE_ACCESS_TOKEN, value: deviceAccessToken });
    const braidUrl = `${BRAID_PROTOCOL}://${PROJECT_HOST}:${PROJECT_PORT}/braid`;
    const braidOptions: Object = { accessToken: deviceAccessToken };
    const userAccessToken = yield* getUserAccessTokenSaga();
    if (userAccessToken) {
      braidOptions.secondaryAccessToken = userAccessToken;
    } else if (useKerberos) {
      const kerberosAccessToken = yield* kerberosUserLoginSaga();
      if (kerberosAccessToken) {
        braidOptions.secondaryAccessToken = kerberosAccessToken;
      }
    }
    if (braidClient.ws) {
      yield call(() => braidClient.close());
    }
    yield call(braidClient.open.bind(braidClient), braidUrl, braidOptions);
  } catch (error) {
    logger.error('Error refreshing access token');
    logger.errorObject(error);
    throw error;
  }
  yield fork(refreshAccessTokensSaga);
}

function* navigateChannelSaga(action: ActionType): Saga<*> {
  try {
    const deviceId = yield select(deviceIdSelector);
    yield put(changeScreenChannel(deviceId, action.value));
  } catch (error) {
    logger.error('Unable to navigate to channel');
    logger.errorObject(error);
  }
}

function* clearScreenChannelSaga(): Saga<*> {
  const availableSources = yield select(availableSourcesSelector);
  const audioSource = yield select(audioSourceSelector);
  if (availableSources && availableSources.size !== 0 || audioSource !== null) {
    const deviceId = yield select(deviceIdSelector);
    yield put(updateBandDevice(deviceId, { availableSources: [], audioSource: null }));
  }
  const activeChannelId = yield select(activeChannelIdSelector);
  if (activeChannelId !== null) {
    yield put({ type: constants.SET_ACTIVE_CHANNEL_ID, value: null });
  }
  if (window.location.pathname !== '/') {
    yield put(push('/'));
  }
}

export function* getChannelIdSaga(teamName: string, channelPathname: string): Saga<*> {
  const link = `${teamName}/${channelPathname}`;
  const linkChannelIdCached = localStorage.getItem(`link:${link}`);
  if (linkChannelIdCached) {
    yield fork(_getChannelIdSaga, teamName, channelPathname);
    return linkChannelIdCached;
  }
  const channelId = yield* _getChannelIdSaga(teamName, channelPathname);
  return channelId;
}

export function* _getChannelIdSaga(teamName: string, channelPathname: string): Saga<*> { // eslint-disable-line no-underscore-dangle
  let response;
  const link = `${teamName}/${channelPathname}`;
  try {
    response = yield call(() => agent.post('/api/v1.0/band-channel/getid').send({ link }));
  } catch (error) {
    logger.error(`Error fetching channel ID by link: ${link}`);
    logger.errorStack(error);
    return null;
  }
  if (response && response.body && response.body.id) {
    const channelId = response.body.id;
    localStorage.setItem(`link:${link}`, channelId);
    localStorage.setItem(`link:${channelId}`, link);
    return channelId;
  }
  localStorage.removeItem(`link:${link}`);
  return null;
}

function* getChannelPathSaga(channelId: string): Saga<*> {
  const channelIdLinkCached = localStorage.getItem(`link:${channelId}`);
  if (channelIdLinkCached) {
    yield fork(_getChannelPathSaga, channelId);
    return channelIdLinkCached;
  }
  const link = yield* _getChannelPathSaga(channelId);
  return link;
}

function* _getChannelPathSaga(channelId: string): Saga<*> { // eslint-disable-line no-underscore-dangle
  let response;
  try {
    response = yield call(() => agent.post('/api/v1.0/band-channel/getlink').send({ id: channelId }));
  } catch (error) {
    logger.error(`Error fetching channel path for id: ${channelId}`);
    logger.errorStack(error);
    return null;
  }
  if (response && response.body && response.body.link) {
    const link = response.body.link;
    localStorage.setItem(`link:${link}`, channelId);
    localStorage.setItem(`link:${channelId}`, link);
    return link;
  }
  localStorage.removeItem(`link:${channelId}`);
  return null;
}

function* waitForOnlineSaga() {
  const start = Date.now();
  const deviceId = yield select(deviceIdSelector);
  const teamId = yield* getApplicationTeamIdSaga();
  const channel = getReduxChannel(`online-status/${teamId}/${deviceId}`);
  logger.debug('Waiting for online status');
  try {
    while (true) {
      const ips = yield take(channel);
      if (ips && List.isList(ips) && ips.size > 0) {
        logger.debug(`Online with ${ips.size === 1 ? 'IP' : 'IPS'}: ${[...ips]} after ${Date.now() - start}ms`);
        channel.close();
      }
    }
  } finally {} // eslint-disable-line no-empty
}

function* channnelListenerSaga() {
  const deviceId = yield select(deviceIdSelector);
  const teamId = yield* getApplicationTeamIdSaga();
  const channel = getReduxChannel(`n/${deviceId}/ancestors?type=${TYPE_BAND_CHANNEL}&depth=1&hasParent=${teamId}`, List());
  logger.debug('Channel listener event channel started.');
  try {
    while (true) {
      const channelAncestors = yield take(channel);
      const activeChannelId = yield select(activeChannelIdSelector);
      const channelId = channelAncestors && List.isList(channelAncestors) ? channelAncestors.first() : null;
      logger.debug(`Channel listener received active channel ID ${activeChannelId || '"none"'} and channel ID ${channelId || '"none"'}`);
      if (channelId && channelId !== activeChannelId) {
        const pathname = yield* getChannelPathSaga(channelId);
        logger.debug(`Channel listener switching to pathname ${pathname || '"none"'}`);
        if (pathname && pathname !== window.location.pathname.slice(1)) {
          yield put(push(`/${pathname}`));
        }
        yield put({ type: constants.SET_ACTIVE_CHANNEL_ID, value: channelId });
      } else if (!channelId) {
        yield* clearScreenChannelSaga();
      }
    }
  } finally {
    logger.debug('Channel listener event channel terminated.');
  }
}

export function* switchTeamSaga(action: ActionType): Saga<*> {
  const teamName = yield select(teamNameSelector);
  if (action.value === teamName) {
    yield put({ type: constants.CLOSE_DIALOG, value: { dialog: 'switchTeam' } });
    return;
  }
  try {
    yield* deviceLogoutSaga();
    yield put({ type: constants.SET_TEAM_NAME, value: action.value });
    yield put({ type: constants.CLOSE_DIALOG, value: { dialog: 'switchTeam' } });
  } catch (error) {
    logger.error('Unable to switch teams');
    logger.errorObject(error);
    yield put({ type: constants.SET_TEAM_NAME, value: teamName });
  }
  logger.error('Running setup saga, switchTeamsSaga');
  yield* setupSaga();
}

export function* switchChannelSaga(action: ActionType): Saga<*> {
  const teamName = action.value.teamName;
  const channelName = action.value.channelName;
  const cachedTeamName = window.localStorage.getItem('clientTeamName');
  if (teamName && cachedTeamName !== teamName) {
    try {
      yield* deviceLogoutSaga();
      yield put({ type: constants.SET_TEAM_NAME, value: teamName });
    } catch (error) {
      logger.error('Error switching team while changing channel: ', error);
    }
  }
  logger.debug('Running setup saga, switchChannelSaga');
  yield* setupSaga();
  const channelId = yield* getChannelIdSaga(teamName, channelName);
  const deviceId = yield select(deviceIdSelector);
  const teamId = yield select(teamIdSelector);
  const channelAncestors = cachedValue(`n/${deviceId}/ancestors?type=${TYPE_BAND_CHANNEL}&depth=1&hasParent=${teamId}`) || List();
  const cachedChannelId = channelAncestors && List.isList(channelAncestors) ? channelAncestors.first() : null;
  if (cachedChannelId !== channelId) {
    yield put(changeScreenChannel(deviceId, channelId));
  }
}

let previousVolume;
let previousMute;
async function handleVolume(metadata: ImmutableMap<string, *>): Promise<ImmutableMap<string, *>> {
  const isDeviceAvailable = await getIsDeviceAvailable();
  if (!isDeviceAvailable) {
    return metadata;
  }
  const volume = metadata.get('volume');
  const mute = metadata.get('muted');
  if (previousVolume !== volume || previousMute !== mute) {
    previousVolume = volume;
    previousMute = mute;
    return new Promise(async (resolve: Function) => {
      let timeout = false;
      const timeoutId = setTimeout(() => {
        timeout = true;
        resolve(metadata);
      }, 2000);
      try {
        if (isDeviceAvailable) {
          if (mute) {
            await blendDevice.setVolume(0);
          } else {
            await blendDevice.setVolume(volume * 100);
          }
        }
        if (!timeout) {
          clearTimeout(timeoutId);
          resolve(metadata.set('volume', 1));
        }
      } catch (error) {
        if (!timeout) {
          clearTimeout(timeoutId);
          resolve(metadata);
        }
        logger.errorObject(error);
      }
    });
  }
  return metadata;
}

let previousMeetingVolume;
let previousMeetingMuted;
let previousMeetingVideo;
let previousLeaveMeeting;
let previousMeetingCameraSwitch;
let previousMeetingParticipantMic;
let previousMeetingParticipantVideo;
let previousMeetingChangeView;
function* handleMeeting(metadata: ImmutableMap<string, *>, deviceId: string): Saga<ImmutableMap<string, *>> {
  const zoomMeetingId = yield select(zoomMeetingIdSelector);
  const isZoomRoomAvailable = yield call(getIsZoomRoomAvailable);
  if (!zoomMeetingId) {
    return metadata;
  }
  const zoomClientAvailable = isZoomRoomAvailable && zoomRoomClient;
  const meetingVolume = metadata.get('meetingVolume');
  const meetingMuted = metadata.get('meetingMuted');
  const meetingVideo = metadata.get('meetingVideo');
  const meetingParticipantsList = metadata.get('meetingParticipantsList');
  const meetingParticipantMic = metadata.get('meetingParticipantMic');
  const meetingParticipantVideo = metadata.get('meetingParticipantVideo');
  const meetingViewList = metadata.get('meetingViewList');
  const meetingChangeView = metadata.get('meetingChangeView');
  const meetingCamera = metadata.get('meetingCamera');
  const meetingCameraList = metadata.get('meetingCameraList');
  const meetingCameraSwitch = metadata.get('meetingCameraSwitch');
  const leaveMeeting = metadata.get('leaveMeeting');

  const handleCamera = (data: Object) => {
    const { participantId, action, start } = data;
    if (start) {
      zoomRoomClient.zcommand.call.cameraControl({ id: participantId.toString(), state: 'Start', action });
      zoomRoomClient.zcommand.call.cameraControl({ id: participantId.toString(), state: 'Continue', action });
    } else {
      zoomRoomClient.zcommand.call.cameraControl({ id: participantId.toString(), state: 'Stop', action });
    }
  };

  if (zoomMeetingId &&
    (previousMeetingVolume !== meetingVolume) ||
    (previousMeetingMuted !== meetingMuted) ||
    (previousMeetingVideo !== meetingVideo) ||
    (previousLeaveMeeting !== leaveMeeting)) {
    try {
      if (leaveMeeting !== undefined && previousLeaveMeeting !== leaveMeeting) {
        if (!previousLeaveMeeting) {
          previousLeaveMeeting = leaveMeeting;
        } else {
          if (zoomClientAvailable) yield call(zoomRoomClient.zcommand.call.leave);
          yield put(changeScreenChannel(deviceId, null, { resetChannel: false }));
          previousLeaveMeeting = leaveMeeting;
          return metadata;
        }
      }
      if (meetingVideo !== undefined && previousMeetingVideo !== meetingVideo) {
        if (meetingVideo === true) {
          if (zoomClientAvailable) yield call(zoomRoomClient.zconfiguration.call.camera, { mute: 'off' });
          previousMeetingVideo = meetingVideo;
        }
        if (meetingVideo === false) {
          if (zoomClientAvailable) yield call(zoomRoomClient.zconfiguration.call.camera, { mute: 'on' });
          previousMeetingVideo = meetingVideo;
        }
      }
      if (meetingMuted !== undefined && previousMeetingMuted !== meetingMuted) {
        if (meetingMuted === true) {
          if (zoomClientAvailable) yield call(zoomRoomClient.zconfiguration.call.microphone, { mute: 'on' });
          previousMeetingMuted = meetingMuted;
        }
        if (meetingMuted === false) {
          if (zoomClientAvailable) yield call(zoomRoomClient.zconfiguration.call.microphone, { mute: 'off' });
          previousMeetingMuted = meetingMuted;
        }
      }
      if (meetingVolume !== undefined && previousMeetingVolume !== meetingVolume) {
        if (zoomClientAvailable) yield call(zoomRoomClient.zconfiguration.audio.output.volume, meetingVolume);
        previousMeetingVolume = meetingVolume;
      }
    } catch (error) {
      return metadata;
    }
  }
  if (zoomMeetingId && meetingParticipantsList) {
    try {
      if (meetingParticipantsList === true) {
        if (zoomClientAvailable) {
          yield call(zoomRoomClient.zcommand.call.listParticipants);
        }
      }
      if (typeof meetingParticipantMic !== 'string' && ImmutableMap.isMap(meetingParticipantMic) && !is(previousMeetingParticipantMic, meetingParticipantMic)) {
        if (meetingParticipantMic.toJS().micStatus === true && meetingParticipantMic.toJS().participantId) {
          if (zoomClientAvailable) {
            yield call(() => zoomRoomClient.zcommand.call.muteParticipant({ mute: 'on', id: meetingParticipantMic.toJS().participantId.toString() }));
            previousMeetingParticipantMic = meetingParticipantMic;
          }
        }
        if (meetingParticipantMic.toJS().micStatus === false && meetingParticipantMic.toJS().participantId) {
          if (zoomClientAvailable) {
            yield call(() => zoomRoomClient.zcommand.call.muteParticipant({ mute: 'off', id: meetingParticipantMic.toJS().participantId.toString() }));
            previousMeetingParticipantMic = meetingParticipantMic;
          }
        }
      }
      if (meetingParticipantMic === 'muteAll' && previousMeetingParticipantMic !== meetingParticipantMic) {
        if (zoomClientAvailable) {
          yield call(zoomRoomClient.zcommand.call.muteAll, { mute: 'on' });
          previousMeetingParticipantMic = meetingParticipantMic;
        }
      }
      if (meetingParticipantMic === 'unmuteAll' && previousMeetingParticipantMic !== meetingParticipantMic) {
        if (zoomClientAvailable) {
          yield call(zoomRoomClient.zcommand.call.muteAll, { mute: 'off' });
          previousMeetingParticipantMic = meetingParticipantMic;
        }
      }
      if (ImmutableMap.isMap(meetingParticipantVideo) && !is(previousMeetingParticipantVideo, meetingParticipantVideo)) {
        if (meetingParticipantVideo.toJS().videoStatus === true && meetingParticipantVideo.toJS().participantId) {
          if (zoomClientAvailable) {
            yield call(() => zoomRoomClient.zcommand.call.muteParticipantVideo({ mute: 'on', id: meetingParticipantVideo.toJS().participantId.toString() }));
            previousMeetingParticipantVideo = meetingParticipantVideo;
          }
        }
        if (meetingParticipantVideo.toJS().videoStatus === false && meetingParticipantVideo.toJS().participantId) {
          if (zoomClientAvailable) {
            yield call(() => zoomRoomClient.zcommand.call.muteParticipantVideo({ mute: 'off', id: meetingParticipantVideo.toJS().participantId.toString() }));
            previousMeetingParticipantVideo = meetingParticipantVideo;
          }
        }
      }
    } catch (error) {
      logger.error('Error in meetingParticipants metadata');
      logger.errorObject(error);
      return metadata;
    }
  }
  if (zoomMeetingId && meetingViewList) {
    try {
      if (meetingViewList === true) {
        if (zoomClientAvailable) {
          yield call(zoomRoomClient.zcommand.call.listParticipants);
          yield call(zoomRoomClient.zstatus.call.layout);
        }
      }
      if (meetingChangeView === 'Speaker' && previousMeetingChangeView !== meetingChangeView) {
        if (zoomClientAvailable) {
          yield call(zoomRoomClient.zconfiguration.call.layout, { style: 'Speaker' });
          previousMeetingChangeView = meetingChangeView;
        }
      }
      if (meetingChangeView === 'Strip' && previousMeetingChangeView !== meetingChangeView) {
        if (zoomClientAvailable) {
          yield call(zoomRoomClient.zconfiguration.call.layout, { style: 'Strip' });
          previousMeetingChangeView = meetingChangeView;
        }
      }
      if (meetingChangeView === 'Gallery' && previousMeetingChangeView !== meetingChangeView) {
        if (zoomClientAvailable) {
          yield call(zoomRoomClient.zconfiguration.call.layout, { style: 'Gallery' });
          previousMeetingChangeView = meetingChangeView;
        }
      }
    } catch (error) {
      logger.error('Error in meetingView metadata');
      logger.errorObject(error);
      return metadata;
    }
  }
  if (zoomMeetingId && meetingCamera) {
    try {
      if (ImmutableMap.isMap(meetingCamera) && zoomClientAvailable) {
        if (meetingCamera.toJS().open === true) {
          braidClient.removeServerEventListener(`zoom/${deviceId}/camera`);
          braidClient.addServerEventListener(`zoom/${deviceId}/camera`, handleCamera);
        }
        if (meetingCamera.toJS().open === false) {
          braidClient.removeServerEventListener(`zoom/${deviceId}/camera`);
        }
      }
    } catch (error) {
      logger.error('Error in meetingCamera metadata');
      logger.errorObject(error);
      return metadata;
    }
  }
  if (zoomMeetingId && meetingCameraList) {
    try {
      if (meetingCameraList === true && zoomClientAvailable) {
        yield call(zoomRoomClient.zstatus.video.camera.line);
      }
    } catch (error) {
      logger.error('Error in meetingCameraList metadata');
      logger.errorObject(error);
      return metadata;
    }
  }
  if (zoomMeetingId && (previousMeetingCameraSwitch !== meetingCameraSwitch)) {
    try {
      if (meetingCameraSwitch && zoomClientAvailable) {
        previousMeetingCameraSwitch = meetingCameraSwitch;
        yield call(zoomRoomClient.zconfiguration.video.camera.selectedID, meetingCameraSwitch);
      }
    } catch (error) {
      logger.error('Error in meetingCameraSwitch metadata');
      logger.errorObject(error);
      return metadata;
    }
  }
  return metadata;
}

function* cacheDeviceName(deviceId: string, metadata: Map<string, *>): Saga<*> {
  const deviceName = metadata.get('name');
  const deviceNickname = metadata.get('nickname');
  if (deviceName) {
    window.localStorage.setItem('deviceName', deviceName);
  }
  if (deviceNickname) {
    window.localStorage.setItem('deviceNickname', deviceNickname);
  }
  if (!deviceName) {
    const cachedDeviceName = window.localStorage.getItem('deviceName');
    const cachedDeviceNickname = window.localStorage.getItem('deviceNickname');
    if (cachedDeviceName) {
      yield put(updateBandDevice(deviceId, {
        name: cachedDeviceName,
        nickname: cachedDeviceNickname,
      }));
    } else {
      let userAgent: Object = getUserAgentParameters();
      if (!userAgent) {
        yield delay(1000);
        userAgent = getUserAgentParameters();
      }
      const deviceOs = userAgent.isKiosk ? 'KIOSK' : userAgent.osName;
      const macAddress = yield call(getMacAddress);
      const name = `${macAddress || Date.now().toString(36)}-${deviceOs}-${userAgent.browserName}`.replace(/\s/g, '').toUpperCase();
      yield put(updateBandDevice(deviceId, { name, macAddress }));
    }
  }
}

export function* deviceMetadataSaga(): Saga<*> {
  const deviceId = yield select(deviceIdSelector);
  const channel = getReduxChannel(`n/${deviceId}`, ImmutableMap());
  logger.debug('Device metadata event channel started.');
  try {
    while (true) {
      const node = yield take(channel);
      let metadata = node && ImmutableMap.isMap(node) ? node.get('metadata') : null;
      if (metadata) {
        metadata = yield call(handleVolume, metadata);
        metadata = yield* handleMeeting(metadata, deviceId);
        yield put(setDeviceMetadata(metadata));
        yield* cacheDeviceName(deviceId, metadata);
      }
    }
  } finally {
    logger.debug('Device metadata event channel terminated.');
  }
}

export function* initialDeviceMetadataSaga(): Saga<*> {
  const deviceId = yield select(deviceIdSelector);
  const node = yield call(() => cachedSnapshot(`n/${deviceId}`));
  const metadata = node && ImmutableMap.isMap(node) ? node.get('metadata') : null;
  if (!metadata) {
    return;
  }
  const deviceMetadataUpdates = {};
  if (typeof metadata.get('canUseWebRtc') !== 'boolean') {
    deviceMetadataUpdates.canUseWebRtc = yield call(() => canUseWebRtcPromise);
  }
  if (Object.keys(deviceMetadataUpdates).length > 0) {
    yield put(updateBandDevice(deviceId, deviceMetadataUpdates));
  }
}

export function* teamMetadataSaga(): Saga<*> {
  const teamId = yield select(teamIdSelector);
  const channel = getReduxChannel(`n/${teamId}`, ImmutableMap());
  logger.debug('Team metadata event channel started.');
  try {
    while (true) {
      const node = yield take(channel);
      if (node && ImmutableMap.isMap(node) && node.get('metadata')) {
        const zoomRoomPasscode = node.getIn(['metadata', 'zoomRoomPasscode']);
        yield put(setZoomRoomPasscode(zoomRoomPasscode));
        const urls = node.getIn(['metadata', 'storageUrls']);
        if (List.isList(urls)) {
          urls.forEach((url) => {
            boltClient.addServer(url);
          });
        }
        const useMulticast = node.getIn(['metadata', 'useMulticast']);
        yield put({ type: constants.SET_USE_MULTICAST, value: !!useMulticast });
        const hardwareManagerIp = node.getIn(['metadata', 'hardwareManagerIp']);
        yield put({ type: constants.SET_HARDWARE_MANAGER_IP, value: hardwareManagerIp });
        yield* updateHardwareManagerIp();
      }
    }
  } finally {
    logger.debug('Team metadata event channel terminated.');
  }
}

function* updateHardwareManagerIp(): Saga<*> {
  const { isKiosk } = getUserAgentParameters();
  if (!isKiosk) {
    return;
  }
  const hardwareManagerIp = yield select(hardwareManagerIpSelector);
  const blendExtensionStatus = yield select(blendExtensionStatusSelector);
  if (blendExtensionStatus && hardwareManagerIp) {
    try {
      yield call(apiClient.updateHardwareManagerIp, hardwareManagerIp);
    } catch (error) {
      logger.error('Error updating hardware manager ip');
      logger.errorStack(error);
    }
  }
}

export function* metadataNodeSaga(): Saga<*> {
  const metadataId = yield select(metadataIdSelector);
  const channel = getReduxChannel(`n/${metadataId}`, ImmutableMap());
  logger.debug('Theme event channel started.');
  try {
    while (true) {
      const node = yield take(channel);
      if (node && ImmutableMap.isMap(node) && node.get('metadata')) {
        const color = node.getIn(['metadata', 'color']);
        const secondaryColor = node.getIn(['metadata', 'secondaryColor']);
        const clock24Hr = node.getIn(['metadata', 'clock24Hr']) || false;
        yield put({ type: constants.SET_CLOCK_24HR, value: clock24Hr });
        yield put({ type: constants.SET_THEME, value: { color, secondaryColor } });
        const keepQrCodeOnTop = node.getIn(['metadata', 'keepQrCodeOnTop']);
        yield put({ type: constants.KEEP_QR_CODE_ON_TOP, value: !!keepQrCodeOnTop });
      }
    }
  } finally {
    logger.debug('Theme event channel terminated.');
  }
}

export function* checkAutoplaySaga(): Saga<void> {
  const { isKiosk, isBrightSign } = getUserAgentParameters();
  if (isKiosk) {
    yield put(setCanAutoplayUnmuted(true));
    return;
  }
  if (isBrightSign) {
    yield put(setCanAutoplayUnmuted(true));
    return;
  }
  if (isIE) {
    yield put(setCanAutoplayUnmuted(true));
    return;
  }
  yield call(() => onloadPromise);
  try {
    const responseUnmuted = yield call(() => canAutoplay.video({ inline: true, muted: false }));
    if (!responseUnmuted.result) {
      logger.info('Browser autoplay disabled, videos will start muted.');
    }
    yield put(setCanAutoplayUnmuted(!!responseUnmuted.result));
  } catch (error) {
    logger.error('Error checking autoplay');
    logger.error(error);
  }
}

export function* layoutVideoSaga(): Saga<void> {
  const channel = yield actionChannel([constants.ADD_LAYOUT_VIDEO, constants.REMOVE_LAYOUT_VIDEO]);
  while (true) {
    const action = yield take(channel);
    const immutableAvailableSources = yield select(availableSourcesSelector);
    let audioSource = yield select(audioSourceSelector);
    const availableSources = List.isList(immutableAvailableSources) ? new Set(immutableAvailableSources.toJS()) : new Set();
    if (action && action.type === constants.ADD_LAYOUT_VIDEO) {
      if (availableSources.has(action.value)) {
        continue;
      }
      availableSources.add(action.value);
    } else {
      if (!availableSources.has(action.value)) {
        continue;
      }
      availableSources.delete(action.value);
    }
    const sortedAudioSources = [...availableSources];
    sortedAudioSources.sort();
    if (availableSources.size === 0) {
      audioSource = null;
    } else if (!availableSources.has(audioSource) || audioSource === null) {
      audioSource = sortedAudioSources[0];
    }
    const deviceId = yield select(deviceIdSelector);
    yield put(updateBandDevice(deviceId, { availableSources: sortedAudioSources, audioSource }));
  }
}

export function* updateBandDeviceSaga(action: ActionType): Saga<void> {
  const zoomMeetingId = yield select(zoomMeetingIdSelector);
  if (zoomMeetingId) {
    yield put(updateBandDevice(action.value.id, action.value.metadata));
  }
}

export function* boltClientSaga(): Saga<void> {
  yield call(() => boltClient.ready);
  yield put({ type: constants.BOLT_CLIENT_READY, value: true });
}

export function* userAgentParamsSaga(): Saga<void> {
  const params = getUserAgentParameters();
  yield put({ type: constants.SET_USER_AGENT_PARAMS, value: params });
}

let zoomRoomClient;
let meetingNeedsPassword;

export function* setupZoomRoomClient(passcode:string, attempt?:number = 1): Saga<void> {
  zoomRoomClient = new ZoomRoomClient(passcode);
  try {
    yield call(() => zoomRoomClient.ready);
  } catch (error) {
    yield put(setZoomRoomAvailable(false));
    const duration = attempt < 8 ? attempt * attempt * 1000 : 60000;
    logger.warn(`%cZoom Rooms: %cUnable to connect to Zoom Room Control System, retrying in ${duration / 1000} seconds`);
    yield delay(duration);
    yield spawn(setupZoomRoomClient, passcode, attempt + 1);
    return;
  }
  yield put(setZoomRoomAvailable(true));
  const deviceId = yield select(deviceIdSelector);
  const channel = eventChannel((emit: Function) => {
    const handleStatus = (key, data) => {
      if (key === 'Call') {
        const { Status: status } = data;
        if (status === 'IN_MEETING') {
          meetingNeedsPassword = undefined;
          emit({ type: constants.ZOOM_MEETING_NODE_CHECK });
          emit({ type: constants.ZOOM_SHARING_STATUS });
          emit({ type: constants.EMIT_ZOOM_IN_MEETING });
        }
        if (status === 'NOT_IN_MEETING') {
          if (!meetingNeedsPassword) {
            emit({ type: constants.EMIT_ZOOM_MEETING_END, value: status });
          }
        }
      }
      if (key === 'Sharing') {
        const { directPresentationSharingKey, password, serverName } = data;
        emit({ type: constants.EMIT_ZOOM_SHARING_KEY, value: { directPresentationSharingKey, password, serverName } });
      }
      if (key === 'Layout') {
        emit({ type: constants.EMIT_ZOOM_VIEW_LIST, value: { videoType: data.video_type, canSwitchGallery: data.can_Switch_Wall_View } });
      }
      if (key === 'Video Camera Line') {
        emit({ type: constants.EMIT_ZOOM_CAMERA_LIST, value: data });
      }
    };
    const handleConfiguration = (key, data) => {
      if (key === 'Audio' && data.Output && data.Output.volume) {
        const meetingVolume = data.Output.volume;
        if (previousMeetingVolume !== meetingVolume) {
          previousMeetingVolume = meetingVolume;
          emit(updateDevice(deviceId, { meetingVolume }));
        }
      }
      if (key === 'Call') {
        if (data.Camera && data.Camera.Mute) {
          const meetingVideo = data.Camera.Mute === 'on';
          if (previousMeetingVideo !== meetingVideo) {
            previousMeetingVideo = meetingVideo;
            emit(updateDevice(deviceId, { meetingVideo }));
          }
        }
        if (data.Microphone && data.Microphone.Mute) {
          const meetingMuted = data.Camera.Mute === 'on';
          if (previousMeetingMuted !== meetingMuted) {
            previousMeetingMuted = meetingMuted;
            emit(updateDevice(deviceId, { meetingMuted }));
          }
        }
      }
      if (key === 'Video') {
        emit({ type: constants.UPDATE_ZOOM_CAMERA_LIST });
      }
    };
    const handleCommand = (key, data) => {
      if (key === 'InfoResult') {
        const { meeting_id: meetingId } = data;
        if (meetingId) {
          emit({ type: constants.EMIT_ZOOM_MEETING_ID, value: meetingId });
        }
      }
      if (key === 'ListParticipantsResult') {
        if (Array.isArray(data)) {
          emit({ type: constants.EMIT_ZOOM_PARTICIPANTS, value: data });
        } else {
          emit({ type: constants.UPDATE_ZOOM_PARTICIPANTS });
        }
      }
      if (key === 'CallMuteParticipantVideoResult') {
        emit({ type: constants.UPDATE_ZOOM_PARTICIPANTS });
      }
    };
    const handleEvent = (key, data) => {
      if (key === 'MeetingNeedsPassword') {
        meetingNeedsPassword = data;
        emit({ type: constants.EMIT_ZOOM_MEETING_NEEDS_PASSWORD, value: data });
      }
      if (key === 'NeedWaitForHost' && data.Wait) {
        emit({ type: constants.ZOOM_MEETING_NODE_CHECK });
        emit({ type: constants.EMIT_ZOOM_IN_MEETING });
        emit({ type: constants.ZOOM_SHARING_STATUS });
      }
    };
    const handleError = () => {
      emit(setZoomRoomAvailable(false));
    };
    const handleClose = () => {
      emit(setZoomRoomAvailable(false));
    };
    const handleOpen = () => {
      emit(setZoomRoomAvailable(true));
    };
    zoomRoomClient.on('zConfiguration', handleConfiguration);
    zoomRoomClient.on('zStatus', handleStatus);
    zoomRoomClient.on('zCommand', handleCommand);
    zoomRoomClient.on('zEvent', handleEvent);
    zoomRoomClient.on('error', handleError);
    zoomRoomClient.on('close', handleClose);
    zoomRoomClient.on('open', handleOpen);
    return () => {
      zoomRoomClient.removeListener('zConfiguration', handleConfiguration);
      zoomRoomClient.removeListener('zStatus', handleStatus);
      zoomRoomClient.removeListener('zCommand', handleCommand);
      zoomRoomClient.removeListener('zEvent', handleEvent);
      zoomRoomClient.removeListener('error', handleError);
      zoomRoomClient.removeListener('close', handleClose);
      zoomRoomClient.removeListener('open', handleOpen);
    };
  }, buffers.expanding(2));
  try {
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } finally {
    logger.debug('Zoom room client event channel terminated.');
  }
}

export function* setZoomRoomPasscodeSaga(action:ActionType): Saga<void> {
  const isZoomRoomAvailable = yield select(zoomRoomAvailableSelector);
  if (zoomRoomClient || !isZoomRoomAvailable) {
    return;
  }
  yield spawn(setupZoomRoomClient, action.value);
}

export function* setZoomRoomAvailableSaga(action:ActionType): Saga<void> {
  if (zoomRoomClient || !action.value) {
    return;
  }
  if (typeof action.value === 'undefined') {
    return;
  }
  const passcode = yield select(zoomRoomPasscodeSelector);
  if (typeof passcode === 'undefined') {
    return;
  }
  yield spawn(setupZoomRoomClient, passcode);
}

export function* startLocalZoomMeetingSaga(): Saga<void> {
  if (!zoomRoomClient) {
    return;
  }
  try {
    yield call(() => zoomRoomClient.zcommand.dial.startPMI({ duration: 60 }));
    const meetingVolume = yield select(meetingVolumeSelector);
    if (meetingVolume) {
      yield call(zoomRoomClient.zconfiguration.audio.output.volume, meetingVolume);
    }
    yield put(closeDialog('loadMeeting'));
  } catch (error) {
    logger.error('Error starting local zoom meeting');
    logger.errorStack(error);
  }
}

export function* joinLocalZoomMeetingSaga(action: ActionType): Saga<void> {
  if (!zoomRoomClient) {
    return;
  }
  const { meetingNumber, password } = action.value;
  try {
    yield call(() => zoomRoomClient.zcommand.dial.join({ meetingNumber, password }));
  } catch (error) {
    logger.error('Error joining local zoom meeting');
    logger.errorStack(error);
    yield put(addNotification('Unable to join Zoom meeting'));
  }
}

export function* startLocalZoomShareSaga(): Saga<void> {
  if (!zoomRoomClient) {
    return;
  }
  try {
    yield call(() => zoomRoomClient.zcommand.dial.sharing({ duration: 60, displayState: 'Laptop', password: '' }));
    yield put({ type: constants.CLOSE_DIALOG, value: { dialog: 'loadMeeting' } });
  } catch (error) {
    logger.error('Error starting local zoom share');
    logger.errorStack(error);
    yield put(addNotification('Unable to start Zoom meeting share'));
  }
}

export function* emitZoomMeetingIdSaga(action: ActionType): Saga<void> {
  try {
    const activeZoomMeetingId = yield select(zoomMeetingIdSelector);
    const zoomClientMeetingId = yield select(zoomClientMeetingIdSelector);
    if (parseInt(activeZoomMeetingId, 10) === parseInt(action.value, 10)) {
      return;
    }
    if (zoomClientMeetingId === action.value) {
      return;
    }
    const deviceId = yield select(deviceIdSelector);
    yield put(joinMeeting({ meetingId: action.value, ids: [deviceId] }));
    yield put({ type: constants.SET_ZOOM_MEETING_ID, value: action.value });
  } catch (error) {
    logger.error('Error in emit zoom meeting id saga');
    logger.errorStack(error);
    yield put(addNotification('Unable to create Zoom meeting'));
  }
}

export function* zoomMeetingNodeCheckSaga(): Saga<void> {
  try {
    const activeZoomMeetingNodeId = yield select(zoomMeetingNodeIdSelector);
    const deviceId = yield select(deviceIdSelector);
    const zoomClientMeetingId = yield select(zoomClientMeetingIdSelector);
    if (!activeZoomMeetingNodeId) {
      yield put(joinMeeting({ meetingId: zoomClientMeetingId, ids: [deviceId] }));
    }
  } catch (error) {
    logger.error('Error in zoom meeting node check saga');
    logger.errorStack(error);
  }
}

export function* emitZoomMeetingEndSaga(): Saga<void> {
  try {
    const activeZoomMeetingId = yield select(zoomMeetingIdSelector);
    if (!activeZoomMeetingId) {
      // Band ended meeting, do nothing
      return;
    }
    // End meeting on Band if Zoom Room ends meeting
    const activeZoomMeetingNodeId = yield select(zoomMeetingNodeIdSelector);
    yield put(endMeeting(activeZoomMeetingNodeId));
    const deviceId = yield select(deviceIdSelector);
    yield put(changeScreenChannel(deviceId, null, { resetChannel: false }));
  } catch (error) {
    logger.error('Error in emit zoom meeting id saga');
    logger.errorStack(error);
    yield put(addNotification('Unable to create Zoom meeting'));
  }
}

export function* emitZoomMeetingNeedsPasswordSaga(action: ActionType): Saga<void> {
  try {
    const activeZoomMeetingNodeId = yield select(zoomMeetingNodeIdSelector);
    yield put(updateMeeting(activeZoomMeetingNodeId, { needsPassword: action.value.needsPassword, wrongAndRetry: action.value.wrongAndRetry }));
    if (action.value.wrongAndRetry) {
      yield put(openDialog('password', { type: 'wrongAndRetry' }));
    }
    if (action.value.needsPassword) {
      yield put(openDialog('password', { type: 'needsPassword' }));
    }
  } catch (error) {
    logger.error('Error in emit zoom meeting needs password saga');
    logger.errorStack(error);
  }
}

export function* emitZoomInMeetingSaga(): Saga<void> {
  try {
    const activeZoomMeetingNodeId = yield select(zoomMeetingNodeIdSelector);
    yield put(updateMeeting(activeZoomMeetingNodeId, { inMeeting: true }));
    yield call(zoomRoomClient.zcommand.call.listParticipants);
  } catch (error) {
    logger.error('Error in emit zoom in meeting saga');
    logger.errorStack(error);
  }
}

export function* emitZoomSharingKeySaga(action: ActionType): Saga<void> {
  try {
    const activeZoomMeetingNodeId = yield select(zoomMeetingNodeIdSelector);
    yield put(updateMeeting(activeZoomMeetingNodeId, { sharingKey: action.value.directPresentationSharingKey, airplayPassword: action.value.password, serverName: action.value.serverName }));
  } catch (error) {
    logger.error('Error in emit zoom in meeting saga');
    logger.errorStack(error);
  }
}

export function* emitZoomParticipantsSaga(action: ActionType): Saga<void> {
  try {
    const activeZoomMeetingNodeId = yield select(zoomMeetingNodeIdSelector);
    yield put(updateMeeting(activeZoomMeetingNodeId, { participants: action.value }));
  } catch (error) {
    logger.error('Error in emit zoom participants saga');
    logger.errorStack(error);
  }
}

export function* updateZoomParticipantsSaga(): Saga<void> {
  try {
    yield call(zoomRoomClient.zcommand.call.listParticipants);
  } catch (error) {
    logger.error('Error in update zoom participants saga');
    logger.errorStack(error);
  }
}

export function* updateZoomCameraListSaga(): Saga<void> {
  try {
    yield call(zoomRoomClient.zstatus.video.camera.line);
  } catch (error) {
    logger.error('Error in update zoom camera list saga');
    logger.errorStack(error);
  }
}

export function* emitZoomViewListSaga(action: ActionType): Saga<void> {
  try {
    const activeZoomMeetingNodeId = yield select(zoomMeetingNodeIdSelector);
    yield put(updateMeeting(activeZoomMeetingNodeId, { videoType: action.value.videoType, canSwitchGallery: action.value.canSwitchGallery }));
  } catch (error) {
    logger.error('Error in emit zoom view list saga');
    logger.errorStack(error);
  }
}

export function* emitZoomCameraListSaga(action: ActionType): Saga<void> {
  try {
    const activeZoomMeetingNodeId = yield select(zoomMeetingNodeIdSelector);
    yield put(updateMeeting(activeZoomMeetingNodeId, { cameras: action.value }));
  } catch (error) {
    logger.error('Error in emit zoom view list saga');
    logger.errorStack(error);
  }
}

export function* zoomSharingStatusSaga(): Saga<void> {
  try {
    if (!zoomRoomClient) {
      return;
    }
    yield call(() => zoomRoomClient.zstatus.sharing());
  } catch (error) {
    logger.error('Error in emit zoom sharing key saga');
    logger.errorStack(error);
  }
}

export function* startLocalZoomPhoneSaga(action: ActionType): Saga<void> {
  if (!zoomRoomClient) {
    return;
  }
  try {
    yield call(zoomRoomClient.zcommand.dial.phoneCallOut, { number: action.value });
  } catch (error) {
    logger.error('Error dialing into zoom meeting');
    logger.errorStack(error);
    yield put(addNotification('Unable to dial into Zoom Meeting'));
  }
}

export function* leaveZoomMeetingSaga(): Saga<void> {
  if (!zoomRoomClient) {
    return;
  }
  try {
    yield call(() => zoomRoomClient.zcommand.call.leave());
  } catch (error) {
    logger.error('Error leaving zoom meeting');
    logger.errorStack(error);
    yield put(addNotification('Unable to leave Zoom meeting'));
  }
}

export function* updateMeetingSaga(action: ActionType): Saga<void> {
  try {
    yield put(updateZoomMeeting(action.value.id, action.value.metadata));
  } catch (error) {
    logger.error('Error in update meeting saga');
    logger.errorStack(error);
  }
}

export function* setClock24HrSaga(): Saga<void> {
  yield fork(updateClockSaga);
}

export function* setRemoteUrlSaga(): Saga<*> {
  const { atob } = window;
  if (!atob) {
    return;
  }
  yield take(constants.SET_DEVICE_ACCESS_TOKEN);
  while (true) {
    const application = yield* getApplicationSaga();
    const clientId = application.metadata.client.client_id;
    const privateKey = yield* getPrivateKeySaga();
    const deviceId = yield select(deviceIdSelector);
    const utctime = new Date().toUTCString();
    const providerId = application.metadata.provider.uuid;
    const teamName = yield select(teamNameSelector);
    const teamId = yield select(teamIdSelector);
    const data = {
      deviceId,
      teamName,
      teamId,
      credentials: {
        client_id: clientId,
        grant_type: 'crypto',
        scope: 'openid bridge',
        id: deviceId,
        signature: sign(privateKey, `${deviceId}:${utctime}`),
        utctime,
        provider: providerId,
      },
    };
    const url = new URL(window.location.href);
    url.pathname = '/remote';
    url.search = `?screen=${encodeURIComponent(btoa(JSON.stringify(data)))}`;
    yield put(setRemoteUrl(url.toString()));
    yield delay(20000);
  }
}

export default function* defaultSaga(): Saga<*> {
  yield takeLatest(constants.USER_LOGIN, userLoginSaga);
  yield takeLatest(constants.LOGOUT_USER, logoutUserSaga);
  yield takeLatest(constants.SWITCH_TEAM, switchTeamSaga);
  yield takeLatest(constants.SWITCH_CHANNEL, switchChannelSaga);
  yield takeLatest(constants.NAVIGATE_CHANNEL, navigateChannelSaga);
  yield takeLatest(CLEAR_SCREEN_CHANNEL, clearScreenChannelSaga);
  yield takeLatest(constants.SET_ZOOM_ROOM_PASSCODE, setZoomRoomPasscodeSaga);
  yield takeLatest(constants.SET_ZOOM_ROOM_AVAILABLE, setZoomRoomAvailableSaga);
  yield takeLatest(constants.START_LOCAL_ZOOM_MEETING, startLocalZoomMeetingSaga);
  yield takeLatest(constants.JOIN_LOCAL_ZOOM_MEETING, joinLocalZoomMeetingSaga);
  yield takeLatest(constants.START_LOCAL_ZOOM_SHARE, startLocalZoomShareSaga);
  yield takeLatest(constants.START_LOCAL_ZOOM_PHONE, startLocalZoomPhoneSaga);
  yield takeLatest(constants.LEAVE_ZOOM_MEETING, leaveZoomMeetingSaga);
  yield takeLatest(constants.EMIT_ZOOM_MEETING_ID, emitZoomMeetingIdSaga);
  yield takeLatest(constants.EMIT_ZOOM_MEETING_END, emitZoomMeetingEndSaga);
  yield takeLatest(constants.EMIT_ZOOM_MEETING_NEEDS_PASSWORD, emitZoomMeetingNeedsPasswordSaga);
  yield takeLatest(constants.EMIT_ZOOM_IN_MEETING, emitZoomInMeetingSaga);
  yield takeLatest(constants.ZOOM_SHARING_STATUS, zoomSharingStatusSaga);
  yield takeLatest(constants.EMIT_ZOOM_SHARING_KEY, emitZoomSharingKeySaga);
  yield takeLatest(constants.EMIT_ZOOM_PARTICIPANTS, emitZoomParticipantsSaga);
  yield takeLatest(constants.EMIT_ZOOM_CAMERA_LIST, emitZoomCameraListSaga);
  yield takeLatest(constants.UPDATE_ZOOM_PARTICIPANTS, updateZoomParticipantsSaga);
  yield takeLatest(constants.UPDATE_ZOOM_CAMERA_LIST, updateZoomCameraListSaga);
  yield takeLatest(constants.EMIT_ZOOM_VIEW_LIST, emitZoomViewListSaga);
  yield takeLatest(constants.ZOOM_MEETING_NODE_CHECK, zoomMeetingNodeCheckSaga);
  yield takeLatest(constants.UPDATE_DEVICE, updateBandDeviceSaga);
  yield takeLatest(constants.UPDATE_MEETING, updateMeetingSaga);
  yield takeLatest(constants.SET_CLOCK_24HR, setClock24HrSaga);
  yield fork(updateSizeSaga);
  yield fork(checkAutoplaySaga);
  yield fork(userAgentParamsSaga);
  yield fork(teamSetupSaga);
  yield fork(boltClientSaga);
  yield fork(setRemoteUrlSaga);
  yield fork(blendExtensionSaga);
}
