// @flow

/* eslint-disable jsx-a11y/media-has-caption */

import * as React from 'react';
import Hls from 'hls.js';
import Video2 from 'components/Video2';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import boltClient from '@bunchtogether/bolt-client';
import { updateBandDevice } from 'band-redux/src/app/actions';
import { boltClientReadySelector, deviceIdSelector } from 'containers/App/selectors';
import { hlsDebugLogger, videoDebugLogger } from './logger';

type Props = {
  onError?: (error:Error, data?:Object) => void,
  className?: string,
  style?: Object,
  autoPlay?: boolean,
  controls?: boolean,
  height?: number,
  intrinsicsize?:string,
  loop?: boolean,
  muted?: boolean,
  playsInline?: boolean,
  poster?: string,
  preload?: "none" | "metadata" | "auto" | "",
  src: string | null,
  width?: number,
  volume?: number,
  forwardedRef?: any,
  handleNextStream?: Function,
  active?: boolean,
  debug?: boolean,
  id?: string,
  boltClientReady?: boolean,
  captions?: boolean,
  accessToken?: string, // eslint-disable-line
  hasKey?: boolean,
  updateBandDevice?: Function,
  deviceId?: string,
};

type State = {
  audio: boolean | null,
  video: boolean | null,
  manuallyPaused: boolean,
}

export class Video2Hls extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      audio: null,
      video: null,
      manuallyPaused: false,
    };
    this.reconnectAttempt = 0;
    this.totalReconnectAttempts = 0;
  }

  componentDidMount() {
    if (this.props.active) {
      this.initialize(this.props.src);
    }
    this.handleCaptions(this.props);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src !== prevProps.src || this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.initialize(this.props.src);
      } else {
        this.handleCloseConnections();
      }
    }
    if (prevProps.captions !== this.props.captions) {
      this.handleCaptions(this.props);
    }
  }

  componentWillUnmount() {
    this.handleCloseConnections();
    this.reconnectAttempt = 0;
    this.totalReconnectAttempts = 0;
  }

  initialize = (src: string | null) => {
    this.handleCloseConnections();
    if (!Hls.isSupported()) {
      return;
    }
    if (!this.videoElement) {
      console.log('%cVideo element does not exist, skipping initialization', 'color: #00CC00');
      return;
    }
    if (!src) {
      console.log('%csrc parameter does not exist, skipping initialization', 'color: #00CC00');
      return;
    }
    function elementIsPlaying() {
      if (!element) {
        return false;
      }
      return !!(element.currentTime > 0 && !element.paused && !element.ended && element.readyState > 2);
    }
    const element = this.videoElement;
    element.textTracks.addEventListener('addtrack', () => {
      this.handleCaptions(this.props);
    });
    element.addEventListener('pause', this.handlePause);
    element.addEventListener('play', this.handlePlay);
    const hlsOptions:Object = {
      levelLoadingMaxRetry: Infinity,
      manifestLoadingMaxRetry: Infinity,
      liveDurationInfinity: true,
      levelLoadingMaxRetryTimeout: 10000,
      forceKeyFrameOnDiscontinuity: false,
    };
    if (this.props.hasKey && this.props.accessToken) {
      hlsOptions.xhrSetup = (xhr, url) => {
        if (url.indexOf(boltClient.getUrl('')) === -1) {
          xhr.setRequestHeader('Authorization', `Bearer ${this.props.accessToken || ''}`);
        }
      };
    }
    const hls = new Hls(hlsOptions);
    this.hls = hls;
    let isReconnecting = false;
    let reconnectAttemptResetTimeout = null;
    let reinitializeTimeout = null;
    const reconnectAfterDelay = () => {
      if (isReconnecting) {
        console.log('%cReconnect in progress, skipping', 'color: #00CC00');
        return;
      }
      isReconnecting = true;
      if (reconnectAttemptResetTimeout) {
        clearTimeout(reconnectAttemptResetTimeout);
        reconnectAttemptResetTimeout = null;
      }
      this.reconnectAttempt += 1;
      this.totalReconnectAttempts += 1;
      if (this.reconnectAttempt > 3 && this.props.handleNextStream) {
        this.props.handleNextStream();
        console.log(`%cFalling over to next available stream after ${this.reconnectAttempt} reconnect attempts`, 'font-weight: bold; color: #00CC00');
        this.reconnectAttempt = 0;
      }
      if (this.totalReconnectAttempts > 10) {
        console.log(`%cReloading page after ${this.totalReconnectAttempts} recovery requests`, 'font-weight: bold; color: #00CC00');
        window.location.reload(true);
        return;
      }
      const delay = this.reconnectAttempt > 5 ? 30000 : this.reconnectAttempt * this.reconnectAttempt * 1000;
      console.log(`%cReconnecting after ${Math.round(delay / 100) / 10} seconds`, 'font-weight: bold; color: #00CC00');
      hls.destroy();
      delete this.hls;
      reinitializeTimeout = setTimeout(() => {
        this.initialize(this.props.src);
      }, delay);
    };
    let recoveryStart = Date.now();
    let recoveryTimeout = null;
    const handlePlaying = () => {
      console.log(`%cRecovered after ${Math.round((Date.now() - recoveryStart) / 100) / 10} seconds`, 'color: #00CC00');
      if (recoveryTimeout) {
        clearTimeout(recoveryTimeout);
      }
      recoveryTimeout = null;
      element.removeEventListener('playing', handlePlaying);
      reconnectAttemptResetTimeout = setTimeout(() => {
        this.reconnectAttempt = 0;
      }, 15000);
    };

    const ensureRecovery = () => {
      if (elementIsPlaying()) {
        console.log('%cElement is playing, skipping recovery detection', 'color: #00CC00');
        return;
      }
      if (recoveryTimeout) {
        console.log('%cRecovery detection already in progress, skipping', 'color: #00CC00');
        return;
      }
      console.log('%cEnsuring recovery', 'font-weight: bold; color: #00CC00');
      recoveryStart = Date.now();
      recoveryTimeout = setTimeout(() => {
        recoveryTimeout = null;
        if (elementIsPlaying()) {
          console.log('%cDetected playing element after recovery timeout', 'color: #00CC00');
          handlePlaying();
          return;
        }
        console.log('%cTimeout after attempted recovery', 'color: #00CC00');
        reconnectAfterDelay();
        element.removeEventListener('playing', handlePlaying);
      }, 10000);
      element.addEventListener('playing', handlePlaying);
    };
    hls.loadSource(src);
    hls.attachMedia(element);
    hls.on(Hls.Events.STREAM_STATE_TRANSITION, (event, data) => {
      if (data.nextState === 'ENDED') {
        reconnectAfterDelay();
      }
      if (data.nextState === 'STOPPED') {
        ensureRecovery();
      }
    });
    let audio = null;
    let video = null;
    hls.on(Hls.Events.BUFFER_CODECS, (event, data) => {
      if ((audio !== null && audio !== !!data.audio) || (video !== null && video !== !!data.video)) {
        hls.recoverMediaError();
        ensureRecovery();
      }
      audio = audio && !!data.audio;
      video = video && !!data.video;
      this.setState({ audio: !!audio, video: !!video });
    });
    let fatalMediaErrors = 0;
    let fatalNetworkErrors = 0;
    hls.on(Hls.Events.ERROR, (event, data) => {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            fatalNetworkErrors += 1;
            if (fatalNetworkErrors > 1) {
              reconnectAfterDelay();
              return;
            }
            hls.startLoad();
            ensureRecovery();
            return;
          case Hls.ErrorTypes.MEDIA_ERROR:
            fatalMediaErrors += 1;
            if (fatalMediaErrors > 1) {
              hls.swapAudioCodec();
            }
            hls.recoverMediaError();
            ensureRecovery();
            return;
          default:
            break;
        }
        reconnectAfterDelay();
      } else if (data.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
        ensureRecovery();
      }
    });
    const handleElementError = (event: Event) => {
      if (event.type !== 'error') {
        return;
      }
      const mediaError = element.error;
      if (mediaError && mediaError.code === mediaError.MEDIA_ERR_DECODE) {
        hls.recoverMediaError();
      }
      ensureRecovery();
    };
    element.addEventListener('error', handleElementError);
    if (this.props.debug) {
      hlsDebugLogger(hls);
      videoDebugLogger(element);
    }
    const checkIsPlayingInterval = setInterval(() => {
      if (!elementIsPlaying() && !this.state.manuallyPaused) {
        ensureRecovery();
      }
    }, 5000);
    this.clearInitialization = () => {
      console.log('%cClearing initialization handlers', 'color: #00CC00');
      if (checkIsPlayingInterval) {
        clearInterval(checkIsPlayingInterval);
      }
      if (reinitializeTimeout) {
        clearTimeout(reinitializeTimeout);
        reinitializeTimeout = null;
      }
      if (recoveryTimeout) {
        clearTimeout(recoveryTimeout);
        recoveryTimeout = null;
      }
      if (reconnectAttemptResetTimeout) {
        clearTimeout(reconnectAttemptResetTimeout);
        reconnectAttemptResetTimeout = null;
      }
      element.removeEventListener('error', handleElementError);
      element.removeEventListener('playing', handlePlaying);
      element.removeEventListener('pause', this.handlePause);
      element.removeEventListener('play', this.handlePlay);
    };
    ensureRecovery();
  }

  handlePause = () => this.setState({ manuallyPaused: true });
  handlePlay = () => this.setState({ manuallyPaused: false });

  handleCloseConnections = () => {
    if (this.clearInitialization) {
      this.clearInitialization();
      delete this.clearInitialization;
    }
    const hls = this.hls;
    delete this.hls;
    if (hls) {
      hls.off(Hls.Events.STREAM_STATE_TRANSITION);
      hls.off(Hls.Events.BUFFER_CODECS);
      hls.off(Hls.Events.ERROR);
      hls.off(Hls.Events.ERROR);
      hls.destroy();
    }
  }

  handleError = (error:Error, data?: Object) => {
    if (this.props.onError) {
      this.props.onError(error, data);
    }
  }

  handleCaptions = (props: Props) => {
    if (!this.videoElement) {
      return;
    }
    const element = this.videoElement;
    if (element.textTracks && element.textTracks.length > 0) {
      element.textTracks.addEventListener('change', this.handleCaptionStatus, false);
      for (let i = 0; i < element.textTracks.length; i += 1) {
        const track = element.textTracks[i];
        if ((track.kind === 'captions' && track.language === 'en') || (track.kind === 'subtitles' && track.language === 'eng')) {
          track.mode = props.captions ? 'showing' : 'hidden';
          return;
        }
      }
    }
  }

  handleCaptionStatus = (event: { currentTarget: any}) => {
    if (!event || !event.currentTarget) {
      return;
    }
    const tracks = event.currentTarget;
    if (!tracks || tracks.length === 0) {
      return;
    }
    if (this.props.updateBandDevice && this.props.deviceId) {
      for (let i = 0; i < tracks.length; i += 1) {
        if ((tracks[i].kind === 'captions' && tracks[i].language === 'en') || (tracks[i].kind === 'subtitles' && tracks[i].language === 'eng')) {
          if (tracks[i].mode === 'showing') {
            this.props.updateBandDevice(this.props.deviceId, { captions: true });
          }
          if (tracks[i].mode === 'disabled' || tracks[i].mode === 'hidden') {
            this.props.updateBandDevice(this.props.deviceId, { captions: false });
          }
        }
      }
    }
  }

  videoElement: HTMLVideoElement;
  hls: Hls;
  reconnectAttempt: number;
  totalReconnectAttempts: number;
  clearInitialization: Function | void;

  render() {
    const {
      forwardedRef,
      className,
      style,
      autoPlay,
      controls,
      height,
      intrinsicsize,
      loop,
      muted,
      playsInline,
      poster,
      preload,
      width,
      src,
      volume,
      active,
      id,
      boltClientReady,
    } = this.props;
    if (!src) {
      return null;
    }
    const calculatedPoster = poster || (src && boltClientReady ? boltClient.getUrl(`api/1.0/thumbnail/${encodeURIComponent(src)}/thumbnail.jpg`) : undefined);
    return (
      <Video2
        key={id}
        id={id}
        onError={this.handleError}
        style={style}
        className={className}
        autoPlay={autoPlay}
        controls={controls}
        height={height}
        intrinsicsize={intrinsicsize}
        loop={loop}
        audioOnly={!!this.state.audio && !this.state.video}
        muted={muted}
        volume={volume}
        playsInline={playsInline}
        poster={calculatedPoster}
        preload={preload}
        width={width}
        active={active}
        src={Hls.isSupported() ? undefined : src}
        ref={(e) => {
          if (e) {
            this.videoElement = e;
            if (forwardedRef && typeof forwardedRef === 'function') {
              forwardedRef(e);
            }
          }
        }}
      />
    );
  }
}

const withConnect = connect((state: StateType) => ({
  boltClientReady: boltClientReadySelector(state),
  deviceId: deviceIdSelector(state),
}), (dispatch: Function) => bindActionCreators({ updateBandDevice }, dispatch));

const ComposedVideo2Hls = compose(
  withConnect,
)(Video2Hls);

export default React.forwardRef<Props, HTMLVideoElement>((props, ref) => <ComposedVideo2Hls {...props} forwardedRef={ref} />); // eslint-disable-line react/no-multi-comp
