// @flow

/* eslint-disable jsx-a11y/media-has-caption */

import * as React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { compose, bindActionCreators } from 'redux';
import {
  canAutoplayUnmutedSelector,
  isMultiVideoSelector,
  audioSourceSelector,
  deviceIdSelector,
  boltClientReadySelector,
} from 'containers/App/selectors';
import boltClient from '@bunchtogether/bolt-client';
import { addLayoutVideo, removeLayoutVideo, setCanAutoplayUnmuted } from 'containers/App/actions';
import DialogMuteRequired from 'components/DialogMuteRequired';
import { updateBandDevice } from 'band-redux/src/app/actions';
import Mic from '@material-ui/icons/Mic';
import { isIE } from '../../lib/useragent';

type Props = {
  onError?: (error:Error, data?:Object) => void,
  style?: Object,
  autoPlay?: boolean,
  controls?: boolean,
  height?: number,
  intrinsicsize?:string,
  loop?: boolean,
  muted?: boolean,
  playsInline?: boolean,
  poster?: string,
  preload?: "none" | "metadata" | "auto" | "",
  src?: string | null,
  width?: number | string,
  volume?: number,
  canAutoplayUnmuted?: boolean,
  forwardedRef?: any,
  active?: boolean,
  handleNextStream?: Function,
  className?: string,
  id?: string,
  // audioSource?: string,
  updateBandDevice?: Function,
  addLayoutVideo?: Function,
  deviceId?: string,
  boltClientReady?: boolean,
  setCanAutoplayUnmuted?: Function,
  removeLayoutVideo?: Function,
  audioOnly?: boolean,
  onEnded?: Function,
  onTimeUpdate?: Function,
  key?: string,
};

const booleanVideoElementProperties = ['autoPlay', 'controls', 'loop', 'muted', 'playsInline'];
const videoElementProperties = ['intrinsicsize', 'poster', 'preload', 'src'];
const videoElementNumberProperties = ['height', 'width', 'volume'];

type State = {
  muteRequiredDialogOpen: boolean,
  muteRequired: boolean,
  calculatedMuted?: boolean,
  manuallyPaused: boolean,
  mouseActive: boolean
};

export class Video2 extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps(props:Props, prevState:State) {
    const muteRequired = props.canAutoplayUnmuted === undefined ? true : !props.canAutoplayUnmuted;
    const newState:Object = {
      calculatedMuted: props.muted || muteRequired,
    };
    if (!muteRequired && prevState.muteRequired) {
      newState.muteRequired = false; // eslint-disable-line no-param-reassign
    }
    return newState;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      muteRequiredDialogOpen: false,
      muteRequired: typeof props.canAutoplayUnmuted === 'undefined' ? true : !props.canAutoplayUnmuted,
      calculatedMuted: props.muted || (typeof props.canAutoplayUnmuted === 'undefined' ? true : !props.canAutoplayUnmuted),
      manuallyPaused: false,
      mouseActive: false,
    };
    this.handleVolumeChangeDebounced = debounce(this.handleVolumeChange, 250);
    this.reconnectAttempt = 0;
    this.totalReconnectAttempts = 0;
  }

  componentDidMount() {
    if (this.props.addLayoutVideo && this.props.active && this.props.id) {
      this.props.addLayoutVideo(this.props.id);
    }
    if (this.videoElement) {
      this.initializeVideoElement(this.videoElement);
      this.videoElement.addEventListener('error', (event: Object) => {
        if (event.target && event.target.error && event.target.error.code) {
          if (event.target.error.code === event.target.error.MEDIA_ERR_ABORTED) {
            console.log('%cUser aborted video playback', 'font-weight: bold; color: #00CC00');
          }
          if (event.target.error.code === event.target.error.MEDIA_ERR_NETWORK) {
            console.log('%cNetwork error caused video download to fail part-way', 'font-weight: bold; color: #00CC00');
          }
          if (event.target.error.code === event.target.error.MEDIA_ERR_DECODE) {
            console.log('%cVideo playback was aborted due to a corruption problem or because the video used features your browser does not support', 'font-weight: bold; color: #00CC00');
          }
          if (event.target.error.code === event.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED) {
            console.log('%cVideo could not be loaded, either because the server or network failed or because the format is not supported.', 'font-weight: bold; color: #00CC00');
          }
        }
        if (this.props.handleNextStream) {
          setTimeout(() => {
            // $FlowFixMe
            this.props.handleNextStream();
          }, 5000);
        }
        if (this.props.onError) {
          this.props.onError(event.target.error);
        }
      });
      if (this.props.src) {
        this.attachErrorHandlers();
      }
      this.videoElement.addEventListener('ended', () => {
        const { src, active, onEnded } = this.props;
        if (onEnded || !src || !active) {
          return;
        }
        const element = this.videoElement;
        if (!element) {
          return;
        }
        element.pause();
        element.removeAttribute('src'); // Empty source
        element.load();
        element.setAttribute('src', src);
        this.initializeVideoElement(element);
        element.play();
      });
      this.videoElement.addEventListener('volumechange', this.handleVolumeChangeDebounced);
    }
    window.document.addEventListener('mousemove', this.handleMouseMove);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.active) {
      if (typeof this.props.volume !== 'undefined' && typeof prevProps.volume !== 'undefined' && prevProps.volume !== this.props.volume && this.state.muteRequired) {
        this.showMuteRequiredDialog();
      }
      if (this.props.muted === false && typeof prevProps.muted !== 'undefined' && prevProps.muted !== this.props.muted && this.state.muteRequired) {
        this.showMuteRequiredDialog();
      }
    }
    if ((this.props.active !== prevProps.active || this.props.id !== prevProps.id) && this.props.removeLayoutVideo && this.props.addLayoutVideo) {
      if (prevProps.active && prevProps.id) {
        this.props.removeLayoutVideo(prevProps.id);
      }
      if (this.props.active && this.props.id) {
        this.props.addLayoutVideo(this.props.id);
      }
    }
    if (this.videoElement) {
      for (const booleanVideoElementProperty of booleanVideoElementProperties) {
        if (this.props[booleanVideoElementProperty] !== prevProps[booleanVideoElementProperty]) {
          if (booleanVideoElementProperty === 'muted') {
            const { muted } = this.props;
            if (typeof muted === 'boolean') {
              this.setMuted();
            }
            continue;
          }
          if (this.props[booleanVideoElementProperty]) {
            this.videoElement.setAttribute(booleanVideoElementProperty, booleanVideoElementProperty);
          } else {
            this.videoElement.setAttribute(booleanVideoElementProperty, 'null');
          }
        }
      }
      for (const videoElementProperty of videoElementProperties) {
        if (this.props[videoElementProperty] !== prevProps[videoElementProperty]) {
          this.videoElement.setAttribute(videoElementProperty, this.props[videoElementProperty] || 'null');
        }
      }
      for (const videoElementNumberProperty of videoElementNumberProperties) {
        if (this.props[videoElementNumberProperty] !== prevProps[videoElementNumberProperty] && !isNaN(this.props[videoElementNumberProperty])) {
          if (this.props[videoElementNumberProperty] === 'volume') {
            this.videoElement.volume = Math.max(0, Math.min(1, (typeof this.props.volume !== 'number' ? 1 : this.props.volume)));
          } else {
            // $FlowFixMe
            this.videoElement[videoElementNumberProperty] = this.props[videoElementNumberProperty];
          }
        }
      }
    }
    if (!prevProps.src && this.props.src) {
      this.attachErrorHandlers();
    }
    if (prevProps.src && !this.props.src && this.detachErrorHandlers) {
      this.detachErrorHandlers();
    }
    if (prevProps.src !== this.props.src && this.props.src) {
      if (this.videoElement) {
        this.videoElement.pause();
        this.videoElement.removeAttribute('src'); // Empty source
        this.videoElement.load();
        // $FlowFixMe
        this.videoElement.setAttribute('src', this.props.src);
        this.initializeVideoElement(this.videoElement);
        if (this.props.active) {
          this.videoElement.play();
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.props.removeLayoutVideo && this.props.active && this.props.id) {
      this.props.removeLayoutVideo(this.props.id);
    }
    this.videoElement.removeEventListener('volumechange', this.handleVolumeChangeDebounced);
    if (this.detachErrorHandlers) {
      this.detachErrorHandlers();
    }
    window.document.removeEventListener('mousemove', this.handleMouseMove);
  }

  setMuted() {
    if (this.videoElement) {
      if (this.state.calculatedMuted) {
        this.videoElement.setAttribute('muted', 'muted');
        if (isIE && isIE <= 11) {
          this.videoElement.muted = true;
        }
      } else {
        this.videoElement.setAttribute('muted', 'null');
        if (isIE && isIE <= 11) {
          this.videoElement.muted = false;
        }
      }
    }
  }

  attachErrorHandlers() {
    if (this.detachErrorHandlers) {
      this.detachErrorHandlers();
    }
    console.log('%cAttaching video element playback handlers', 'color: #00CC00');
    const element:HTMLVideoElement = this.videoElement;
    if (!element) {
      return;
    }
    function elementIsPlaying() {
      if (!element) {
        return false;
      }
      return !!(element.currentTime > 0 && !element.paused && !element.ended && element.readyState > 2);
    }
    let isReconnecting = false;
    let reconnectAttemptResetTimeout = null;
    let reinitializeTimeout = null;
    element.addEventListener('pause', this.handlePause);
    element.addEventListener('play', this.handlePlay);
    const reconnectAfterDelay = () => {
      if (isReconnecting) {
        console.log(`%cReconnect ${this.reconnectAttempt} in progress, skipping`, 'color: #00CC00');
        return;
      }
      isReconnecting = true;
      if (reconnectAttemptResetTimeout) {
        clearTimeout(reconnectAttemptResetTimeout);
        reconnectAttemptResetTimeout = null;
      }
      this.reconnectAttempt += 1;
      this.totalReconnectAttempts += 1;
      if (this.reconnectAttempt > 3 && this.props.handleNextStream) {
        this.props.handleNextStream();
        console.log(`%cFalling over to next available stream after ${this.reconnectAttempt} reconnect attempts`, 'font-weight: bold; color: #00CC00');
        this.reconnectAttempt = 0;
      }
      if (this.totalReconnectAttempts > 10) {
        console.log(`%cReloading page after ${this.totalReconnectAttempts} recovery requests`, 'font-weight: bold; color: #00CC00');
        window.location.reload(true);
        isReconnecting = false;
        return;
      }
      const delay = this.reconnectAttempt > 5 ? 30000 : this.reconnectAttempt * this.reconnectAttempt * 1000;
      console.log(`%cReconnecting after ${Math.round(delay / 100) / 10} seconds`, 'font-weight: bold; color: #00CC00');
      try {
        element.pause();
        element.removeAttribute('src'); // Empty source
        element.load();
      } catch (error) {
        console.log('%cElement load failure', 'color: #CC0000');
        console.error(error);
      }
      reinitializeTimeout = setTimeout(async () => {
        const src = this.props.src;
        if (!src) {
          isReconnecting = false;
          return;
        }
        try {
          element.setAttribute('src', src);
          this.initializeVideoElement(element);
          await new Promise((resolve, reject) => {
            if (elementIsPlaying()) {
              resolve();
              return;
            }
            const timeout = setTimeout(() => {
              element.removeEventListener('playing', handleRecoveryPlaying);
              element.removeEventListener('error', handleRecoveryError);
              reject(new Error(`Element with source ${src} did not play after ten seconds`));
            }, 10000);
            const handleRecoveryError = () => {
              clearTimeout(timeout);
              element.removeEventListener('playing', handleRecoveryPlaying);
              element.removeEventListener('error', handleRecoveryError);
              reject(new Error(`Element with source ${src} error`));
            };
            const handleRecoveryPlaying = () => {
              clearTimeout(timeout);
              element.removeEventListener('playing', handleRecoveryPlaying);
              element.removeEventListener('error', handleRecoveryError);
              resolve();
            };
            element.addEventListener('playing', handleRecoveryPlaying);
            element.addEventListener('error', handleRecoveryError);
            element.play();
          });
        } catch (error) {
          console.log(`%cElement with source ${src} play failure`, 'color: #CC0000');
          console.error(error);
        }
        isReconnecting = false;
      }, delay);
    };
    let recoveryStart = Date.now();
    let recoveryTimeout = null;
    const handlePlaying = () => {
      console.log(`%cRecovered after ${Math.round((Date.now() - recoveryStart) / 100) / 10} seconds`, 'color: #00CC00');
      if (recoveryTimeout) {
        clearTimeout(recoveryTimeout);
      }
      recoveryTimeout = null;
      element.removeEventListener('playing', handlePlaying);
      reconnectAttemptResetTimeout = setTimeout(() => {
        this.reconnectAttempt = 0;
      }, 15000);
    };
    const ensureRecovery = () => {
      if (elementIsPlaying()) {
        console.log('%cElement is playing, skipping recovery detection', 'color: #00CC00');
        return;
      }
      if (recoveryTimeout) {
        console.log('%cRecovery detection already in progress, skipping', 'color: #00CC00');
        return;
      }
      if (!this.props.active) {
        console.log('%Video is not active, skipping', 'color: #00CC00');
        return;
      }
      console.log('%cEnsuring recovery', 'font-weight: bold; color: #00CC00');
      recoveryStart = Date.now();
      recoveryTimeout = setTimeout(() => {
        recoveryTimeout = null;
        if (elementIsPlaying()) {
          console.log('%cDetected playing element after recovery timeout', 'color: #00CC00');
          handlePlaying();
          return;
        }
        console.log('%cTimeout after attempted recovery', 'color: #00CC00');
        reconnectAfterDelay();
        element.removeEventListener('playing', handlePlaying);
      }, 10000);
      element.addEventListener('playing', handlePlaying);
    };
    const handleElementError = () => {
      ensureRecovery();
    };
    element.addEventListener('error', handleElementError);
    const checkIsPlayingInterval = setInterval(() => {
      if (!elementIsPlaying() && !this.state.manuallyPaused) {
        ensureRecovery();
      }
    }, 5000);
    this.detachErrorHandlers = () => {
      console.log('%cDetaching video element playback handlers', 'color: #00CC00');
      if (checkIsPlayingInterval) {
        clearInterval(checkIsPlayingInterval);
      }
      if (reinitializeTimeout) {
        clearTimeout(reinitializeTimeout);
        reinitializeTimeout = null;
      }
      if (recoveryTimeout) {
        clearTimeout(recoveryTimeout);
        recoveryTimeout = null;
      }
      if (reconnectAttemptResetTimeout) {
        clearTimeout(reconnectAttemptResetTimeout);
        reconnectAttemptResetTimeout = null;
      }
      element.removeEventListener('error', handleElementError);
      element.removeEventListener('playing', handlePlaying);
      element.removeEventListener('pause', this.handlePause);
      element.removeEventListener('play', this.handlePlay);
      delete this.detachErrorHandlers;
    };
  }

  initializeVideoElement(videoElement:HTMLVideoElement) {
    for (const booleanVideoElementProperty of booleanVideoElementProperties) {
      if (booleanVideoElementProperty === 'muted') {
        const { muted } = this.props;
        if (typeof muted === 'boolean') {
          this.setMuted();
        }
        continue;
      }
      if (this.props[booleanVideoElementProperty]) {
        videoElement.setAttribute(booleanVideoElementProperty, booleanVideoElementProperty);
      }
    }
    for (const videoElementProperty of videoElementProperties) {
      if (this.props[videoElementProperty]) {
        videoElement.setAttribute(videoElementProperty, this.props[videoElementProperty] || 'null');
      }
    }
    for (const videoElementNumberProperty of videoElementNumberProperties) {
      if (!isNaN(this.props[videoElementNumberProperty])) {
        if (this.props[videoElementNumberProperty] === 'volume') {
          // $FlowFixMe
          videoElement[videoElementNumberProperty] = Math.max(0, Math.min(1, this.props[videoElementNumberProperty])); // eslint-disable-line no-param-reassign
        } else {
          // $FlowFixMe
          videoElement[videoElementNumberProperty] = this.props[videoElementNumberProperty]; // eslint-disable-line no-param-reassign
        }
      }
    }
  }

  handleVolumeChange = () => {
    const ubd = this.props.updateBandDevice;
    if (ubd && this.videoElement && this.props.deviceId) {
      const volume = Math.round(this.videoElement.volume * 100) / 100;
      const muted = this.videoElement.muted;
      if (this.props.volume !== volume || this.state.calculatedMuted !== muted) {
        this.setState({
          muteRequired: false,
        });
        ubd(this.props.deviceId, { volume, muted: muted || (volume === 0) });
        if (this.props.setCanAutoplayUnmuted) {
          this.props.setCanAutoplayUnmuted(true);
        }
      }
    }
  }

  handlePause = () => this.setState({ manuallyPaused: true });
  handlePlay = () => this.setState({ manuallyPaused: false });
  handleMouseMove = () => {
    if (this.state.mouseActive !== true) {
      this.setState({
        mouseActive: true,
      });
    }
    clearTimeout(this.mouseActiveTimeout);
    this.mouseActiveTimeout = setTimeout(() => {
      this.setState({
        mouseActive: false,
      });
    }, 15000);
  }

  handleMuteRequiredDialogClose = () => {
    clearTimeout(this.muteRequiredDialogTimeout);
    this.setState({
      muteRequiredDialogOpen: false,
    });
  }

  showMuteRequiredDialog() {
    clearTimeout(this.muteRequiredDialogTimeout);
    this.setState({
      muteRequiredDialogOpen: true,
    });
    this.muteRequiredDialogTimeout = setTimeout(() => {
      this.setState({
        muteRequiredDialogOpen: false,
      });
    }, 5000);
  }

  handleLoadStart = () => {
    if (this.videoElement && typeof this.props.volume === 'number') {
      this.videoElement.volume = this.props.volume;
    }
    if (this.props.active && this.videoElement) {
      this.videoElement.play();
    }
  }

  videoElement: HTMLVideoElement;
  muteRequiredDialogTimeout: TimeoutID;
  handleVolumeChangeDebounced: () => void;
  reconnectAttempt: number;
  totalReconnectAttempts: number;
  detachErrorHandlers: void | () => void;
  mouseActiveTimeout: void | TimeoutID;

  render() {
    const { muteRequiredDialogOpen } = this.state;
    const {
      forwardedRef,
      style,
      autoPlay,
      controls,
      height,
      intrinsicsize,
      loop,
      playsInline,
      poster,
      preload,
      width,
      src,
      className,
      boltClientReady,
      active,
      audioOnly,
      key,
    } = this.props;
    const calculatedMuted = isIE && isIE <= 11 ? undefined : this.state.calculatedMuted;
    const calculatedPoster = poster || (src && boltClientReady ? boltClient.getUrl(`api/1.0/thumbnail/${encodeURIComponent(src)}/thumbnail.jpg`) : undefined);
    const videoStyle = this.state.mouseActive ? style : Object.assign({ pointerEvents: 'none' }, style);
    return (
      <React.Fragment>
        {audioOnly ? <Mic style={{ color: '#d3d3d3', fontSize: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-25vh', marginLeft: '-25vh' }} /> : null}
        <video
          key={key}
          style={videoStyle}
          className={className}
          height={height}
          playsInline={playsInline}
          autoPlay={isIE && isIE <= 11 ? undefined : active && autoPlay}
          controls={controls}
          intrinsicsize={isIE && isIE <= 11 ? undefined : intrinsicsize}
          muted={calculatedMuted}
          loop={loop || undefined}
          poster={calculatedPoster}
          preload={preload}
          src={src}
          width={width}
          onLoadStart={this.handleLoadStart}
          onEnded={this.props.onEnded}
          onTimeUpdate={this.props.onTimeUpdate}
          ref={(e) => {
            if (e) {
              this.videoElement = e;
              if (forwardedRef && typeof forwardedRef === 'function') {
                forwardedRef(e);
              }
            }
          }}
        />
        <DialogMuteRequired
          open={muteRequiredDialogOpen}
          onClose={this.handleMuteRequiredDialogClose}
          aria-labelledby='mute-dialog-title'
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect((state: StateType) => ({
  boltClientReady: boltClientReadySelector(state),
  canAutoplayUnmuted: canAutoplayUnmutedSelector(state),
  audioSource: audioSourceSelector(state),
  isMultiVideo: isMultiVideoSelector(state),
  deviceId: deviceIdSelector(state),
}), (dispatch: Function) => bindActionCreators({ updateBandDevice, addLayoutVideo, removeLayoutVideo, setCanAutoplayUnmuted }, dispatch));

const ComposedVideo2 = compose(
  withConnect,
)(Video2);

export default React.forwardRef<Props, HTMLVideoElement>((props, ref) => <ComposedVideo2 {...props} forwardedRef={ref} />); // eslint-disable-line react/no-multi-comp

