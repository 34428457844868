// @flow

import { createSelector } from 'reselect';
import { Map } from 'immutable';

const selectRoute = (state) => state.get('route');

export const makeSelectLocation = () => createSelector<StateType, *, *, *>(
  selectRoute,
  (routeState) => routeState.get('location').toJS(),
);

export const pathnameSelector = (state: StateType) => state.getIn(['route', 'location', 'pathname']);

const appState = (state: StateType) => state.get('app');

export const deviceMetadataSelector = (state: StateType) => state.getIn(['app', 'metadata']);

export const navigationVisibleSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): bool => state.get('navigationVisible'),
);

export const dialogSelector = (state: StateType, dialog: string) => createSelector<StateType, *, *, *>(
  appState,
  (app: Object): boolean => app.getIn(['dialogs', dialog]) || false,
)(state);

export const dialogOptionsSelector = (state: StateType) => createSelector<StateType, *, *, *>(
  appState,
  (app: Object): boolean => app.get('dialogOptions'),
)(state);

export const searchQuerySelector = createSelector<StateType, *, *, *>(
  appState,
  (state: Object): string => state.get('searchQuery'),
);

export const activeLayoutSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: Object): string => state.get('activeLayout'),
);

export const scalaPlayerNameSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): bool => state.get('scalaPlayerName'),
);

export const scalaPlayerIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): bool => state.get('scalaPlayerId'),
);

export const scalaAccountIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): bool => state.get('scalaAccountId'),
);

export const userIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): string => state.get('userId'),
);

export const deviceIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): string => state.get('deviceId'),
);

export const teamIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): string => state.get('teamId'),
);

export const timeSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): string => state.get('time'),
);

export const dateSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): string => state.get('date'),
);

export const clock24HrSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): string => state.get('clock24Hr'),
);

export const logoDataSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): Map<string, *> | null => state.get('logoData'),
);

export const metadataIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): string => state.getIn(['application', 'id']),
);

export const providerIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): string => state.getIn(['application', 'metadata', 'provider', 'uuid']),
);

export const clientIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): string => state.getIn(['application', 'metadata', 'client', 'client_id']),
);

export const redirectUriSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: StateType): string => state.getIn(['application', 'metadata', 'client', 'redirect_uris', 0]),
);

export const loginErrorSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: Object): string | null => state.get('loginError'),
);

export const channelSelectorOpenSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: Object): boolean => state.get('channelSelectorOpen'),
);

export const activeChannelIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state: Object): boolean => state.get('activeChannelId'),
);

export const availableSourcesSelector = createSelector<StateType, *, *, *>(
  deviceMetadataSelector,
  (state: Object): any => state.get('availableSources'),
);

export const isMultiVideoSelector = createSelector<StateType, *, *, *>(
  availableSourcesSelector,
  (state: Object): any => state && state.length > 1,
);

export const boltClientReadySelector = createSelector<StateType, *, *, *>(
  appState,
  (state: Object): string => state.get('boltClientReady'),
);


export const teamNameSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('teamName'),
);

export const themeColorSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('themeColor'),
);

export const meetingVolumeSelector = createSelector<StateType, *, *, *>(
  deviceMetadataSelector,
  (state) => state.get('meetingVolume'),
);

export const volumeSelector = createSelector<StateType, *, *, *>(
  deviceMetadataSelector,
  (state) => state.get('volume'),
);

export const mutedSelector = createSelector<StateType, *, *, *>(
  deviceMetadataSelector,
  (state) => state.get('muted'),
);

export const captionsSelector = createSelector<StateType, *, *, *>(
  deviceMetadataSelector,
  (state) => state.get('captions'),
);

export const audioSourceSelector = createSelector<StateType, *, *, *>(
  deviceMetadataSelector,
  (state) => state.get('audioSource'),
);

export const deviceAccessTokenSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('deviceAccessToken'),
);

export const canAutoplayMutedSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('canAutoplayMuted'),
);

export const canAutoplayUnmutedSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('canAutoplayUnmuted'),
);

export const userAgentParamsSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('userAgentParams'),
);

export const isKioskSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.getIn(['userAgentParams', 'isKiosk']),
);

export const blendExtensionStatusSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('blendExtensionStatus'),
);

export const screenWidthSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('screenWidth'),
);

export const screenHeightSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('screenHeight'),
);

export const zoomRoomPasscodeSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('zoomRoomPasscode'),
);

export const zoomRoomAvailableSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('zoomRoomAvailable'),
);

export const zoomMeetingIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('zoomMeetingId'),
);

export const zoomMeetingNodeIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('zoomMeetingNodeId'),
);

export const zoomClientMeetingIdSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('zoomClientMeetingId'),
);

export const bluescapeAvailableSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('bluescapeAvailable'),
);

export const mouseActiveSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('mouseActive'),
);

export const useMulticastSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('useMulticast'),
);

export const menuButtonVisibleSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('menuButtonVisible'),
);

export const hardwareManagerIpSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('hardwareManagerIp'),
);

export const remoteUrlSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('remoteUrl'),
);

export const keepQrCodeOnTopSelector = createSelector<StateType, *, *, *>(
  appState,
  (state) => state.get('keepQrCodeOnTop'),
);
